<!-- 分销提成方案 -->
<template>
    <div class="wrap">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </div>
  </template>
  <script>
  import TablePage from "@/components/tablePage";
  
  import {
    commissionListAPI,
    isStoDistribution,
    updateBillStatus,
  } from "@/api/vip/distribution/commissionCase"; //接口api
  export default {
    name: "specialprice",
    components: { TablePage },
    dicts: [
      "coupon_mode", //优惠券形式
      "coupon_case_type", //优惠券类型
    ],
    data() {
      return {
        options: {
          mutiSelect: true, // 多选
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          getListApi: commissionListAPI,
          body: {},
          listNo: true, // 序号
          title: "单据信息",
          check: [], // 选中数据
          rowKey: "couponCaseId",
          labelWidth: "100",
          search: [
          {
            label: "方案日期",
            type: "datetimerange",
            model: "",
            noReset: true,
            clearable: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: 'filters',
            tip: '分销员编号/分销员名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '方案编号' },
              { filter: 'billNames', label: '方案名称' }
            ]
          },
          {
            label: "单据号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billNo",
          },
          {
            label: "方案名称",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "billName",
          },
          this.$select({
            key: "bill_status",
            option: {
              filter: "billStatuss",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
          ],
          buttons: [
            {
              click: "add",
              label: "新增",
              icon: "el-icon-plus",
              type: "primary",
              disabled: false,
            },
            {
              click: "audit",
              label: "审核",
              type: "success",
              icon: "el-icon-document",
              disabled: () => !this.options?.check?.length,
            },
            {
              click: "enable",
              label: "启用",
              type: "warning",
              btnType: "dropdown",
              other: [
                {
                  click: "redoEnable",
                  label: "禁用",
                },
              ],
              disabled: () => !this.options?.check?.length,
            },
            {
              click: "copy",
              label: "复制",
              icon: "el-icon-document-copy",
              type: "success",
              disabled: () => !this.options?.check?.length,
            },
            {
                click: "del",
                label: "删除",
                type: "danger",
                icon: "el-icon-delete",
                disabled: () => !this.options?.check?.length,
            },
          ],
          columns: [
            {
              prop: "billNo",
              label: "方案编号",
              type: "link",
              click: "routerLink",
              minWidth: 200,
            },
            {
              prop: "billName",
              label: "方案名称",
              minWidth: 155,
            },
            {
              prop: "isStop",
              label: "启用状态",
              minWidth: 100,
              formatter: (v, row) => row.isStop?  '禁用' : '启用',
            },
            {
              prop: "begTime",
              label: "生效日期",
              minWidth: 120,
            },
            {
              prop: "endTime",
              label: "失效日期",
              minWidth: 120,
            },
            {
              prop: "billStatusName",
              label: "状态",
              minWidth: 100,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 120,
            },
            {
              prop: "createTime",
              label: "创建时间",
              minWidth: 120,
            },
            {
                prop: "auditBy",
                label: "审核人",
                minWidth: 160,
            },
            {
                prop: "auditTime",
                label: "审核时间",
                minWidth: 160,
            },
            {
                prop: "updateBy",
                label: "修改人",
                minWidth: 160,
            },
            {
                prop: "updateTime",
                label: "修改时间",
                minWidth: 160,
            },
          ],
          list: [],
        },
      };
    },
    methods: {
      async handleEvent(type, row) {
        const selectData = this.options.check;
        switch (type) {
          case "routerLink":
            {
              this.$router.push({
                path:'/vip/distribution/commissionCase/commissionDetail',
                query: {
                  billId: row.billId, //优惠券id
                  type: "Update",
                },
              });
            }
            break;
          case "add":
            {
              this.$router.push("/vip/distribution/commissionCase/commissionDetail");
            }
            break;
          case "audit":
            {
              if (!selectData.length) return;
              this.handleBillStatus(
                "审核",
                selectData,
                "/api/system/vip/distribution/commission/updateBillStatus"
              );
            }
            break;
          case "enable":
            {
              if (!selectData.length) return;
              this.handleBillStatus(
                "启用",
                selectData,
                "/api/system/vip/distribution/commission/isStop"
              );
            }
            break;
          case "redoEnable":
            {
              if (!selectData.length) return;
              this.handleBillStatus(
                "禁用",
                selectData,
                "/api/system/vip/distribution/commission/isStop"
              );
            }
          break;
          case "del":
            {
              if (!selectData.length) return;
              this.handleBillStatus(
                "删除",
                selectData,
                "/api/system/vip/distribution/commission/updateBillStatus"
              );
            }
            break;
          case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "复制",
              selectData,
              "/api/system/vip/distribution/commission/copy"
            );
          }
          break;
          default:
            break;
        }
      },
      seniorSearch() {},
      handleBillStatus(command, selectData, url) {
        let name = "";
        let billStatus = "";
        let isStop = "";
        if (command === "反审核") {
          name = "反审核";
          billStatus = "0";
        } else {
          if (command === "审核") {
            name = "审核";
            billStatus = "2";
          } else if (command === "删除") {
            name = "删除";
            billStatus = "3";
          } else if (command === "复制") {
            name = "复制";
            // billStatus = "4";
          } else if (command === "启用") {
            name = "启用";
            isStop = false;
          } else if (command === "禁用") {
            name = "禁用";
            isStop = true;
          }
        }
        const billId = selectData.map((i) => i.billId);
        const billNo = selectData.map((i) => i.billNo);
        const $this = this;
        let str = billNo.length > 2 ? `${billNo[0]},${billNo[1]}...` : billNo;
        console.log("billNo", billNo);
        console.log("str", str);
        this.$modal
          // .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
          .confirm(`是否确认${name}单据编号为"` + str + '"的单据项?')
          .then(function () {
            if (["启用", "禁用"].includes(name)) {
              isStoDistribution({ billIds: [...billId], isStop: isStop }).then((res) => {
                $this.$nextTick(() => {
                  $this.options.check = [];
                });
                setTimeout(() => {
                  $this.$refs.tablePage.getList();
                }, 500);
                if (!isStop) {
                  $this.$modal.msgSuccess("会员分销方案启用成功");
                } else {
                  $this.$modal.msgSuccess("会员分销方案禁用成功");
                }
              });
            } else {
              return updateBillStatus([...billId], billStatus, url).then(() => {
                $this.$nextTick(() => {
                  $this.options.check = [];
                });
                $this.$modal.msgSuccess(`${name}成功`);
                setTimeout(() => {
                  $this.$refs.tablePage.getList();
                }, 500);
              });
            }
          });
      },
    },
  };
  </script>
  <style lang="scss" scoped></style>
  