<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from '@/components/tablePage' //表格页面组件
import {
  butlerListApi,
} from '@/api/vip/wxService/kfBirthday'
export default {
  name: 'kfBirthday',
  components: {
    TablePage
  },
  data() {
    return {
      options: {
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '生日管家',
        getListApi: butlerListApi,
        listNo: true, // 序号
        exportOption: {
          show: true,
          fastExportUrl: '/api/system/vip/base/birthday/butler/listExport',
          exportName: '生日管家报表导出'
        },
        //搜索
        search: [
          {
            label: '创建时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            clearable:true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'tels', label: '手机号' },
            ]
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'vipName',
            label: '会员名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'tel',
            label: '手机号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'kfName',
            label: '亲友姓名',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'isCnBirthday',
            label: '是否农历',
            minWidth: 100,
            formatter: (v, row) => row?.isCnBirthday?'是':'否',
            align: 'center'
          },
          {
            prop: 'kfCnBirthday',
            label: '农历生日',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'kfBirthday',
            label: '公历生日',
            minWidth: 120,
            align: 'center',
            formatter: (v, row) => row?.isCnBirthday?'':row?.kfBirthday,
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          }
        ],
      }
    }
  }
}
</script>
