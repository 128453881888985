<template>
    <div class="wrap" v-loading="loading">
      <el-form
        ref="form"
        :model="commissionForm"
        :rules="rules"
        label-width="90px"
      >
        <!-- 顶部按钮 -->
        <div class="btnTOP">
          <el-button type="primary" size="mini" @click="submitForm(false)"
            >保存
          </el-button>
          <el-button type="primary" size="mini" @click="submitForm(true)"
            >保存并新增
          </el-button>
          <el-button type="primary" size="mini" @click="submitAudit"
            >审核
          </el-button>
          <el-button
            @click="$router.push('/vipcard/distribution/commissionCase')"
            size="mini"
            >退出</el-button
          >
        </div>
        <cardTitleCom cardTitle="基本信息" :billStatus = "commissionForm.billStatus" >
          <template slot="cardContent">
            <div class="tableContentTop x-x">
              <el-form-item label="方案编号" prop="billNo">
                <el-input
                  v-model="commissionForm.billNo"
                  class="inputWidthM"
                  size="mini"
                  maxlength="20"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="方案名称" prop="billName">
                <el-input
                  v-model="commissionForm.billName"
                  class="inputWidthM"
                  size="mini"
                  maxlength="40"
                  :disabled="disabled"
                ></el-input>
              </el-form-item>
              <el-form-item label="生效日期" prop="begTime">
                <el-date-picker
                  class="inputWidthM"
                  v-model="commissionForm.begTime"
                  type="datetime"
                  placeholder="选择生效日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="mini"
                  :disabled="disabled"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div class="tableContentTop x-x" style="padding-bottom:10px;">
              <el-form-item label="失效日期" prop="endTime">
                <el-date-picker
                  class="inputWidthM"
                  v-model="commissionForm.endTime"
                  type="datetime"
                  placeholder="选择失效日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="mini"
                  :disabled="disabled"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="分销模式" prop="distributionType">
                <el-select
                  v-model="commissionForm.distributionType"
                  placeholder="请选择分销模式"
                  @change="goodsRangeTypeChange"
                  class="marB10 inputWidthM"
                  size="mini"
                  :disabled="disabled"
                >
                  <el-option label="一级分销" :value="1"></el-option>
                  <el-option label="二级分销" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注">
                <el-input
                  class="inputRemark inputWidthM"
                  v-model="commissionForm.billRemark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }"
                  size="mini"
                  :disabled="disabled"
                />
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom cardTitle="提成类型">
          <template slot="cardContent">
            <div class="tableContent marL15">
              <!-- 按销售提成 -->
              <div>
                <div class="marB10">
                  <el-form-item label="提成范围" prop="goodsRangeType" label-width="80px">
                    <el-select
                      v-model="commissionForm.goodsRangeType"
                      placeholder="请选择提成范围"
                      filterable
                      clearable
                      @change="goodsRangeTypeChange"
                      class="marB10"
                      size="mini"
                      :disabled="disabled"
                    >
                      <el-option label="全部商品" :value="0"></el-option>
                      <el-option label="指定商品" :value="1"></el-option>
                      <el-option label="指定类别" :value="2"></el-option>
                      <el-option label="指定品牌" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-table
                  class="marB10"
                  v-if="commissionForm.goodsRangeType != undefined"
                  v-loading="loadingTable"
                  ref="multipleTable"
                  tooltip-effect="dark"
                  :data="commissionForm.billGoodsDetailItems"
                  border
                  max-height="500"
                >
                  <el-table-column
                    label="序号"
                    type="index"
                    width="80"
                    class-name="allowDrag"
                    align="center"
                  />
                  <el-table-column
                    align="center"
                    label="操作"
                    width="120"
                    v-if="!disabled && commissionForm.goodsRangeType != 0"
                  >
                    <template v-slot="scope">
                      <i
                        @click="row('push', scope.$index)"
                        class="el-icon-circle-plus operatePush"
                      ></i>
                      <i
                        @click="row('del', scope.$index)"
                        class="el-icon-remove operateDel"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="类型"
                    align="center"
                    prop="lineType"
                    width="130"
                    v-if="commissionForm.goodsRangeType != 0"
                  >
                    <template slot-scope="scope">
                      <dict-tag
                        :options="
                          dict.type.vip_applicable_commodities_appoint_type
                        "
                        :value="scope.row.lineType"
                      />
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="编码"
                    width="180"
                    align="center"
                    prop="lineNo"
                    v-if="commissionForm.goodsRangeType != 0"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'billGoodsDetailItems.' + scope.$index + '.lineNo'
                        "
                        :rules="rules[`billGoodsDetailItems.lineNo`]"
                      >
                        <el-input
                          v-model="scope.row.lineNo"
                          :disabled="disabled"
                          @keyup.enter.native="
                            keyup('lineNo', $event, scope.$index)
                          "
                        >
                          <i
                            slot="suffix"
                            class="el-icon-more more"
                            @click="selectLineType"
                          ></i>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="名称"
                    align="center"
                    prop="lineName"
                    width="130"
                    v-if="commissionForm.goodsRangeType != 0"
                  />
                  <el-table-column
                    label="会员级别"
                    align="center"
                    prop="lineName"
                    width="170"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.distributionLevelId'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.distributionLevelId`]
                          "
                        >
                          <el-select
                            class="marR10"
                            style="width: 146px"
                            v-model="scope.row.distributionLevelId"
                            placeholder="请选择会员级别"
                            clearable
                            :disabled="disabled"
                          >
                          <el-option v-for="dict in distributionLevelList" :key="dict.distributionLevelId" :label="dict.distributionLevelName"
                            :value="dict.distributionLevelId" />
                          </el-select>
                        </el-form-item>
                    </template>
                  </el-table-column>

                  <el-table-column
                    label="提成方式"
                    :width="commissionForm.distributionType===2?490:325"
                    align="center"
                    prop="commissionCalcType"
                  >
                    <template slot-scope="scope">
                      <div class="x-x">
                        <el-form-item
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionCalcType'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionCalcType`]
                          "
                        >
                          <el-select
                            class="marR10"
                            style="width: 146px"
                            v-model="scope.row.commissionCalcType"
                            @change="commissionCalcTypeChange(scope.row,1)"
                            placeholder="请选择提成方式"
                            clearable
                            :disabled="disabled"
                          >
                            <el-option
                              label="销售提成率(%)"
                              :value="1"
                            ></el-option>
                            <el-option
                              label="按数量提成(个)"
                              :value="2"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item
                          v-if="scope.row.commissionCalcType == 1"
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionSaleRate1'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionSaleRate1`]
                          "
                        >
                          <el-input
                            class="inputWidth sWidth"
                            v-model="scope.row.commissionSaleRate1"
                            oninput="value=value.replace(/[^0-9.]/g,'')"
                            clearable
                            :disabled="disabled"
                            placeholder="一级销售提成率"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          v-if="scope.row.commissionCalcType == 2"
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionSaleOneMoney1'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionSaleOneMoney1`]
                          "
                        >
                          <el-input
                            class="inputWidth sWidth"
                            v-model="scope.row.commissionSaleOneMoney1"
                            oninput="value=value.replace(/[^0-9.]/g,'')"
                            clearable
                            :disabled="disabled"
                            placeholder="一级按数量提成"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          v-if="scope.row.commissionCalcType == 1&&commissionForm.distributionType===2"
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionSaleRate2'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionSaleRate2`]
                          "
                        >
                          <el-input
                            class="inputWidth sWidth"
                            oninput="value=value.replace(/[^0-9.]/g,'')"    
                            v-model="scope.row.commissionSaleRate2"
                            clearable
                            :disabled="disabled"
                            style="margin-left:10px"
                            placeholder="二级销售提成率"
                          >
                          </el-input>
                        </el-form-item>
                        <el-form-item
                          v-if="scope.row.commissionCalcType == 2&&commissionForm.distributionType===2"
                          :prop="
                            'billGoodsDetailItems.' +
                            scope.$index +
                            '.commissionSaleOneMoney2'
                          "
                          :rules="
                            rules[`billGoodsDetailItems.commissionSaleOneMoney2`]
                          "
                        >
                          <el-input
                            class="inputWidth sWidth"
                            v-model="scope.row.commissionSaleOneMoney2"
                            oninput="value=value.replace(/[^0-9.]/g,'')"
                            clearable
                            :disabled="disabled"
                            style="margin-left:10px"
                            placeholder="二级按数量提成"
                          >
                          </el-input>
                        </el-form-item>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="goodsNo" />
                </el-table>
              </div>
              <!-- 按适用对象 -->
              <div>
                <el-form-item label="适用会员" prop="vipLevelRangeType" label-width="80px">
                    <el-radio-group
                        v-model="commissionForm.vipLevelRangeType"
                        :disabled="disabled"
                    >
                        <el-radio :label="0">全部会员</el-radio>
                        <el-radio :label="1">全部会员+排除</el-radio>
                        <el-radio :label="2">指定会员</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- 指定会员区域 -->
                <div class="selectVip" v-if="vipTable">
                  <div class="vipBtn">
                    <el-button size="mini" @click="openSelectVip" :disabled="disabled"
                      >选择会员
                    </el-button>
                    <el-button size="mini" @click="openSelectVipRank" :disabled="disabled"
                      >选择会员等级
                    </el-button>
                  </div>
                  <!-- 会员表格 -->
                  <comTable
                    class="marB10"
                    :columnLabel="vipTableLable"
                    :tableData="commissionForm.billLevelDetailItems"
                    @delTableRow="delTableRow($event, 'vip')"
                    :disabled="disabled"
                  >
                    <!-- 会员表格插入类型 -->
                    <template #tableMiddle>
                      <el-table-column
                        label="类型"
                        align="center"
                        prop="lineType"
                        width="123"
                      >
                        <template slot-scope="scope">
                          <dict-tag
                            :options="dict.type.member_type"
                            :value="scope.row.lineType"
                          />
                        </template>
                      </el-table-column>
                    </template>
                  </comTable>
                </div>
                </div>
            </div>
          </template>
        </cardTitleCom>
      </el-form>
      <!-- 选择会员级别对话框 -->
      <selectVipRank
        :OpenVipRank.sync="OpenVipRank"
        @getVipRank="getPitchData($event, 'vipRank')"
      ></selectVipRank>
      <!-- 选择会员对话框 -->
      <selectVips
        :OpenVipCards.sync="OpenVipCards"
        @getVipS="getPitchData($event, 'vipItem')"
      ></selectVips>
      <!-- 选择商品信息框 -->
      <selectGoods
        :OpenGoods.sync="openGoods"
        @getGoodsIds="getGoodS"
      ></selectGoods>
      <!-- <selectGoods
        :OpenGoods.sync="openGoods"
        :queryCode="queryCode"
        @getGoodS="getGoodS"
      ></selectGoods> -->
      <!-- 选择商品类别信息框 -->
      <selectCategory
        :OpenCategory.sync="openCategory"
        :queryCode="queryCodeCategory"
        @categoryList="categoryList"
      ></selectCategory>
      <!-- 选择商品品牌信息框 -->
      <selectBrand
        :OpenBrand.sync="openBrand"
        :queryCode="queryCodeBrand"
        @brandList="brandList"
      ></selectBrand>
    </div>
  </template>
  
  <script>
  import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
  import selectGoods from "@/views/components/SelectGoods/SelectGoods.vue"; //选择商品
  import selectCategory from '@/views/components/selectCategory' //选择商品类别
  import selectBrand from '@/views/components/selectBrand' //选择商品品牌
  import selectVipRank from "@/views/marketing/components/selectVipRank.vue"; //会员级别组件
  import selectVips from "@/views/components/selectVips"; //会员组件
  import { listGoods } from '@/api/goods/goods' //商品接口
  import { listCategory } from '@/api/goods/category' //商品类别
  import { listBrand } from '@/api/goods/brand' //品牌
  import { getTimeDate } from '@/utils/newDate' //单据日期
  import { listlevel } from '@/api/vip/distribution/man'
  import { getNewBillNo } from "@/utils/numFormatting"; //获取编号
  import {
    commissionAddAPI,
    commissionDetailAPI,
    commissionUpdateAPI,
    updateBillStatus
  } from "@/api/vip/distribution/commissionCase"; //分销员提成设置
import comTable from "@/views/components/com-table"; //活动规则表格组件
  export default {
    name: 'commissionDetailVip',
    dicts: ['vip_applicable_commodities_appoint_type',"member_type"],
    components: {
      cardTitleCom,
      selectGoods,
      selectCategory,
      selectBrand,
      comTable,
      selectVipRank,
      selectVips
    },
    data () {
      return {
        //选择商品品牌开关
        openBrand: false,
        //传送商品品牌参数
        queryCodeBrand: undefined,
  
        //选择商品类别开关
        openCategory: false,
        //传送商品类别参数
        queryCodeCategory: undefined,
  
        //选择商品信息开关
        openGoods: false,
        //传送商品参数
        queryCode: undefined,
        //禁用
        disabled: false,
  
        //表格遮罩
        loadingTable: false,
        //整体遮罩
        loading: false,
        //旧的表单
        oldForm: {},
        //表单
        commissionForm: {
          billGoodsDetailItems: [{ commissionCalcType: 1 }], //销售提成表格
          distributionType:1,
          billLevelDetailItems: [], //适用会员表格数据
          vipLevelRangeType:'',
        },
        distributionLevelList:[],
        //旧的表单
        oldForm: {},
        // 表单校验
        rules: {
          billNo: [
            {
              required: true,
              message: '方案编号不能为空',
              trigger: ['blur', 'change']
            },
            {
              pattern: /^[a-zA-Z0-9]+$/,
              message: '只能输入数字和英文字符',
              trigger: ['blur', 'change']
            }
          ],
          billName: [
            {
              required: true,
              message: '方案名称不能为空',
              trigger: ['blur', 'change']
            }
          ],
          begTime: [
            {
              required: true,
              message: '生效日期不能为空',
              trigger: ['blur', 'change']
            }
          ],
          endTime: [
            {
              required: true,
              message: '失效日期不能为空',
              trigger: ['blur', 'change']
            }
          ],
          goodsRangeType: [
            {
              required: true,
              message: '提成范围不能为空',
              trigger: ['blur', 'change']
            }
          ],
          vipLevelRangeType:[
            {
              required: true,
              message: '适用会员不能为空',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.lineNo': [
            {
              required: true,
              message: '编码不能为空',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.lineName': [
            {
              required: true,
              message: '名称不能为空',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.distributionLevelId': [
            {
              required: true,
              message: '会员级别不能为空',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.commissionCalcType': [
            {
              required: true,
              message: '提成方式不能为空',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.commissionSaleRate1': [
            {
              required: true,
              message: '销售提成率不能为空',
              trigger: ['blur', 'change']
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: '只能输入大于等于0的数字',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.commissionSaleOneMoney1': [
            {
              required: true,
              message: '按数量提成不能为空',
              trigger: ['blur', 'change']
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: '只能输入大于等于0的数字',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.commissionSaleRate2': [
            {
              required: true,
              message: '销售提成率不能为空',
              trigger: ['blur', 'change']
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: '只能输入大于等于0的数字',
              trigger: ['blur', 'change']
            }
          ],
          'billGoodsDetailItems.commissionSaleOneMoney2': [
            {
              required: true,
              message: '按数量提成不能为空',
              trigger: ['blur', 'change']
            },
            {
              pattern: /^\d+(\.\d+)?$/,
              message: '只能输入大于等于0的数字',
              trigger: ['blur', 'change']
            }
          ]
        },
        //会员级别
        vipTable: false,
        //选择会员级别开关
        OpenVipRank: false,
        //选择会员开关
        OpenVipCards: false,
        //会员表格字段
        vipTableLable: [
          { label: "编号", prop: "lineNo" },
          { label: "名称", prop: "lineName" },
        ],
      }
    },
    beforeRouteEnter (to, from, next) {
      next(async vm => {
        vm.loading = true
        // 重置数据
        vm.reset()
        // 访问组件实例 vm 中的数据和方法
        if (vm.$route.query.type === 'Update') {
          if (vm.$route.query.billId) {
            //获取方案详情
            const { data } = await commissionDetailAPI(vm.$route.query.billId)
            //方案赋值
            vm.commissionForm = data
            //原始单据赋值
            vm.oldForm = JSON.parse(JSON.stringify(vm.commissionForm))
          }
        } else {
          //初始化数据
          vm.commissionForm.billDate = getTimeDate()
          //判断是否有以前新增时保存的数据
          if (JSON.stringify(vm.oldForm) != '{}') {
            vm.commissionForm = vm.oldForm
          }
          vm.oldForm = JSON.parse(JSON.stringify(vm.commissionForm))
        }
        vm.loading = false
      })
    },
    async beforeRouteLeave (to, from, next) {
      // 在用户离开二级路由前弹出询问框
      if (this.commissionForm.billId) {
        this.oldForm = {}
      } else {
        this.oldForm = JSON.parse(JSON.stringify(this.commissionForm))
      }
      next()
    },
    watch:{
      //侦听适用对象
      "commissionForm.vipLevelRangeType": {
        handler(val) {
          if (val) {
            //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
            if(val ===1 || val ===2){
              this.vipTable = true;
            }else{
              this.vipTable = false;
            }
          }else{
            this.vipTable = false;
          }
        },
        immediate: true,
      },
      "commissionForm.billStatus":{
        handler(val) {
          if(val=='2'){
            this.disabled = true;
          }else{
            this.disabled = false;
          }
        },
        immediate: true,
        deep:true
      }
    },
    methods: {
    //获取编号事件
      getNewBillNo,
      //选择商品方法
      selectLineType () {
        if (this.commissionForm.goodsRangeType == 1) {
          //商品
          this.openGoods = true
        } else if (this.commissionForm.goodsRangeType == 2) {
          //商品类别
          this.openCategory = true
        } else if (this.commissionForm.goodsRangeType == 3) {
          //商品品牌
          this.openBrand = true
        }
      },
      //选中的商品品牌
      brandList (value) {
        if (value.length == 0) return
        //去重
        value = value.filter(item => {
          return this.commissionForm.billGoodsDetailItems.every(
            v => v.lineId != item.brandId
          )
        })
        if (value.length == 0) return
        value = value.map(v => {
          let obj = {
            lineType: 2,
            lineId: v.brandId,
            lineNo: v.brandNo,
            lineName: v.brandName,
            commissionCalcType: 1
          }
          return obj
        })
        this.commissionForm.billGoodsDetailItems.push(...value)
        this.commissionForm.billGoodsDetailItems =
          this.commissionForm.billGoodsDetailItems.filter(
            item =>
              (!(JSON.stringify(item) === '{}') &&
                !(item.lineId === undefined)) ||
              !(item.lineId === undefined)
          )
      },
      //选中的商品类别
      categoryList (value) {
        if (value.length == 0) return
        //去重
        value = value.filter(item => {
          return this.commissionForm.billGoodsDetailItems.every(
            v => v.lineId != item.categoryId
          )
        })
        if (value.length == 0) return
        value = value.map(v => {
          let obj = {
            lineType: 3,
            lineId: v.categoryId,
            lineNo: v.categoryNo,
            lineName: v.categoryName,
            commissionCalcType: 1
          }
          return obj
        })
        this.commissionForm.billGoodsDetailItems.push(...value)
        this.commissionForm.billGoodsDetailItems =
          this.commissionForm.billGoodsDetailItems.filter(
            item =>
              (!(JSON.stringify(item) === '{}') &&
                !(item.lineId === undefined)) ||
              !(item.lineId === undefined)
          )
      },
      //选中的商品
      getGoodS (value) {
        if (value.length == 0) return
        //去重
        value = value.filter(item => {
          return this.commissionForm.billGoodsDetailItems.every(
            v => v.lineId != item.goodsId
          )
        })
        if (value.length == 0) return
        value = value.map(v => {
          let obj = {
            lineType: 1,
            lineId: v.goodsId,
            lineNo: v.goodsNo,
            lineName: v.goodsName,
            unitId:v.unitId,
            commissionCalcType: 1
          }
          return obj
        })
  
        this.commissionForm.billGoodsDetailItems.push(...value)
        this.commissionForm.billGoodsDetailItems =
          this.commissionForm.billGoodsDetailItems.filter(
            item =>
              (!(JSON.stringify(item) === '{}') &&
                !(item.lineId === undefined)) ||
              !(item.lineId === undefined)
          )
      },
      //表格单元格回车事件  销售提成
      async keyup (name, event, index) {
        //判断是否输入编码或条码
        if (this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]) {
          let response = undefined
          //判断是否重复的名称
          let idName = undefined
          //传输对象
          let obj = undefined
          if (this.commissionForm.goodsRangeType == 1) {
            //商品
            response = await listGoods({
              goodsNo:
                this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
            })
            idName = 'goodsId'
            //原始赋值
            obj = {
              lineType: 1,
              lineId: response.rows[0].goodsId,
              lineNo: response.rows[0].goodsNo,
              lineName: response.rows[0].goodsName,
              commissionCalcType: 1
            }
          } else if (this.commissionForm.goodsRangeType == 2) {
            //商品类别
            response = await listCategory({
              categoryNo:
                this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
            })
            idName = 'categoryId'
            //原始赋值
            obj = {
              lineType: 3,
              lineId: response.rows[0].categoryId,
              distributionLevelNo: response.rows[0].categoryNo,
              distributionLevelName: response.rows[0].categoryName,
              commissionCalcType: 1
            }
          } else if (this.commissionForm.goodsRangeType == 3) {
            //商品品牌
            response = await listBrand({
              brandNo:
                this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
            })
            idName = 'brandId'
            //原始赋值
            obj = {
              lineType: 2,
              lineId: response.rows[0].brandId,
              distributionLevelNo: response.rows[0].brandNo,
              distributionLevelName: response.rows[0].brandName,
              commissionCalcType: 1
            }
          }
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //去重
            let isRepeat = this.commissionForm.billGoodsDetailItems.every(
              item => item.lineId != response.rows[0][idName]
            )
            if (!isRepeat) {
              this.$message.error('当前表格已有此数据!')
              return
            }
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.commissionForm.billGoodsDetailItems, index, obj)
          } else {
            if (this.commissionForm.goodsRangeType == 1) {
              //商品
              this.queryCode =
                this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
              this.openGoods = true
            } else if (this.commissionForm.goodsRangeType == 2) {
              //商品类别
              this.queryCodeCategory =
                this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
              this.openCategory = true
            } else if (this.commissionForm.goodsRangeType == 3) {
              //商品品牌
              this.queryCodeBrand =
                this.commissionForm.billGoodsDetailItems[`${index}`][`${name}`]
              this.openBrand = true
            }
          }
        } else {
          //没输入直接弹出商品信息框
          event.target.blur()
          if (this.commissionForm.goodsRangeType == 1) {
            //商品
            this.openGoods = true
          } else if (this.commissionForm.goodsRangeType == 2) {
            //商品类别
            this.openCategory = true
          } else if (this.commissionForm.goodsRangeType == 3) {
            //商品品牌
            this.openBrand = true
          }
        }
      },
      //商品类型切换
      goodsRangeTypeChange () {
        this.commissionForm.billGoodsDetailItems = [{ commissionCalcType: 1 }]
        //全部
        if (this.commissionForm.goodsRangeType == 0) {
          this.commissionForm.billGoodsDetailItems[0].lineId = 0
          this.commissionForm.billGoodsDetailItems[0].lineType = 0
        }
      },
      //提成方式切换
      commissionCalcTypeChange (row,index) {
        this.$set(row, 'commissionSaleOneMoney1', undefined)
        this.$set(row, 'commissionSaleRate1', undefined)
        this.$set(row, 'commissionSaleOneMoney2', undefined)
        this.$set(row, 'commissionSaleRate2', undefined)
      },
      //打开选择会员级别对话框
      openSelectVipRank() {
        this.OpenVipRank = true;
      },
      //打开选择会员对话框
      openSelectVip() {
        this.OpenVipCards = true;
      },
      //删除表格行事件(共同删除事件,以type区分表格(门店/商品/会员/时间))
      delTableRow(row, type, index) {
        if (type === "shop") {
          //门店
          //过滤掉删除的数据行
          this.commissionForm.shopItem = this.commissionForm.shopItem.filter((v) => v.shopId !== row.shopId);
        } else if (type === "comboGoodsItem") {
          this.commissionForm.comboItem[index].comboGoodsItem = this.commissionForm.comboItem[
            index
          ].comboGoodsItem.filter((v) => v.lineId !== row.lineId);
        } else if (type === "optionalGoodsItem") {
          this.commissionForm.optionalGoodsItem.splice(row.rowIndex, 1);
        } else if (type === "goods") {
          //商品
          //过滤掉删除的数据行
          this.commissionForm.goodsItem = this.commissionForm.goodsItem.filter((v) => v.lineId !== row.lineId);
        } else if (type === "vip") {
          //会员
          //过滤掉删除的数据行
          this.commissionForm.billLevelDetailItems = this.commissionForm.billLevelDetailItems.filter(
            (v) => v.lineId !== row.lineId
          );
        } else if (type === "time") {
          //时间
          //过滤掉删除的数据行
          this.specialDate = this.specialDate.filter((v) => v.id !== row.id); //累加的时间数组
          this.commissionForm.specialDateItem = this.commissionForm.specialDateItem.filter(
            (v) => v.id !== row.id
          );
        }
      },
      
      //获取选中的数据赋值表格    type区分类型(门店/商品/会员/时间/优惠券)
      getPitchData(data, type) {
        console.log(data);
        console.log(data, type);
        console.log(this.commissionForm.promoType, "this.commissionForm.promoType");

        console.log(data, type);
        let tableData = []; //定义一个数组变量 (存放不同表格)
        let id; //定义一个变量存放不同id名称
        if (type === "shop") {
          //门店数据
          tableData = this.commissionForm.shopItem; //使用引入类型 数组是栈类型 直接赋值栈的地址不变
          id = "shopId"; //门店id名称
        } else if (
          //(商品/商品类别/商品品牌)
          type === "goodsItem" ||
          type === "goodsCategory" ||
          type === "goodsBrand"
        ) {
          //当是固定组合方案/动态组合方案/组合优惠方案 过滤掉组合里商品表格中空的商品行 商品数组是comboGoodsItem
          if (
            this.commissionForm.promoType === "2" ||
            this.commissionForm.promoType === "3" ||
            this.commissionForm.promoType === "10"
          ) {
            if (["2", "10"].includes(this.commissionForm.promoType)) {
              this.commissionForm.comboItem[this.signGroupIndex].comboGoodsItem = this.commissionForm.comboItem[
                this.signGroupIndex
              ].comboGoodsItem.filter((v) => v.goodsId);
            }
            tableData = this.commissionForm.comboItem[this.signGroupIndex].comboGoodsItem;
          } else if (
            this.commissionForm.promoType === "8" &&
            this.signGoodsTable === "giveGoodsTable" &&
            this.commissionForm.buyLargessType === "0"
          ) {
            //当是买满赠送方案并且从赠送商品的任选N件的商品表格点开，获取的商品数据 商品数组是optionalGoodsItem
            //过滤掉空的表格行
            tableData = this.commissionForm.optionalGoodsItem;
          } else if (
            this.commissionForm.promoType === "8" &&
            this.signGoodsTable === "giveGoodsTable" &&
            this.commissionForm.buyLargessType === "1"
          ) {
            //当是买满赠送方案并且从赠送商品的固定赠品的商品表格点开，获取的商品数据 商品数组是fixedGoodsItem
            //过滤掉空的表格行
            this.commissionForm.fixedGoodsItem = this.commissionForm.fixedGoodsItem.filter((v) => v.lineId);
            tableData = this.commissionForm.fixedGoodsItem;
          } else {
            //其他方案的商品数组是goodsItem
            //商品数组
            tableData = this.commissionForm.goodsItem;
          }
          id = "lineId"; //(商品/商品类别/商品品牌)公用id
        } else if (type === "vipItem" || type === "vipRank") {
          //(会员/会员级别)
          tableData = this.commissionForm.billLevelDetailItems;
          id = "lineId"; //(会员/会员级别)公用id
        } else if (type === "time") {
          tableData = getDateChangeDetail(data); //日期时间转换
          this.commissionForm.specialDateItem = [...tableData]; //日期时间直接赋值 不需要过滤相同的
          this.DateTimeList = data;
          return;
        } else if (type === "coupon") {
          //优惠券
          //过滤掉空的表格行
          this.commissionForm.couponLargessItem = this.commissionForm.couponLargessItem.filter(
            (v) => v.couponCaseId
          );
          tableData = this.commissionForm.couponLargessItem;
          id = "couponCaseId";
        }
        //过滤选中数组跟表格里重复的数据
        if (tableData.length >= 1) {
          //当表格数据存在时
          for (let i = 0; tableData.length > i; i++) {
            data = data.filter((v) => {
              //商品表格/会员表格需要添加上类型
              if (type === "goodsItem") {
                //商品
                v.lineType = 1; //商品表格行统一类型
                v.lineId = v.goodsId; //商品表格行统一id
                v.lineNo = v.goodsNo; //统一编号字段
                v.lineName = v.goodsName; //统一名称字段
                if (this.commissionForm.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
              } else if (type === "goodsCategory") {
                //商品类别
                v.lineType = 3;
                v.lineNo = v.categoryNo; //字段转换成商品表格一样的字段
                v.lineName = v.categoryName;
                v.lineId = v.categoryId;
                if (this.commissionForm.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
              } else if (type === "goodsBrand") {
                //商品品牌
                v.lineType = 2;
                v.lineNo = v.brandNo; //字段转换成商品表格一样的字段
                v.lineName = v.brandName;
                v.lineId = v.brandId;
                if (this.commissionForm.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
              } else if (type === "vipItem") {
                //会员
                v.lineType = 1;
                v.lineId = v.vipId; //会员表格行统一id
                v.lineNo = v.vipNo; //统一编号字段
                v.lineName = v.vipName; //统一名称字段
              } else if (type === "vipRank") {
                //会员级别
                v.lineType = 2;
                v.lineId = v.vipLevelId;
                v.lineNo = v.vipLevelNo; //统一编号字段
                v.lineName = v.vipLevelName; //统一名称字段
              } else if (type === "coupon") {
                v = this.assemblyCoupon(v);
              }
              if (type !== "goodsItem") {
                return v[id] !== tableData[i][id]; //选中的数据(门店/商品/会员) 过滤掉已经在表格中已经存在的数据
              } else {
                return v;
              }
            });
          }
          //当是数量特价方案时,选择商品是单选,直接赋值,并添加上一行空规则数组
          if (this.commissionForm.promoType === "1") {
            console.log(data);
            this.$set(this.commissionForm.goodsItem, this.clickGoodsIndex, data[0]);
            return this.$set(
              this.commissionForm.goodsItem[this.clickGoodsIndex],
              "goodsBuyFullItem",
              [{}]
            );
          }
          tableData.push(...data); //再追加在表格里
        } else {
          //当表格数据为空时,直接追加表格里
          data.forEach((v) => {
            //商品表格/会员表格需要添加上类型
            if (type === "goodsItem") {
              //商品
              v.lineType = 1; //商品表格行统一类型
              v.lineId = v.goodsId; //商品表格行统一id
              v.lineNo = v.goodsNo; //统一编号字段
              v.lineName = v.goodsName; //统一名称字段
              if (this.commissionForm.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
            } else if (type === "goodsCategory") {
              //商品类别
              v.lineType = 3;
              v.lineNo = v.categoryNo; //字段转换成商品表格一样的字段
              v.lineName = v.categoryName;
              v.lineId = v.categoryId;
              if (this.commissionForm.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
            } else if (type === "goodsBrand") {
              //商品品牌
              v.lineType = 2;
              v.lineNo = v.brandNo; //字段转换成商品表格一样的字段
              v.lineName = v.brandName;
              v.lineId = v.brandId;
              if (this.commissionForm.promoType === "3") this.$set(v, "limitQty", ""); //当是动态组合方案时,初始化数量为0,0代表不限量
            } else if (type === "vipItem") {
              //会员
              v.lineType = 1;
              v.lineId = v.vipId; //会员表格行统一id
              v.lineNo = v.vipNo; //统一编号字段
              v.lineName = v.vipName; //统一名称字段
            } else if (type === "vipRank") {
              //会员级别
              v.lineType = 2;
              v.lineId = v.vipLevelId;
              v.lineNo = v.vipLevelNo; //统一编号字段
              v.lineName = v.vipLevelName; //统一名称字段
            } else if (type === "coupon") {
              v = this.assemblyCoupon(v);
            }
            console.log(v);
            tableData.push(v);
            console.log(tableData);
            console.log(this.commissionForm.fixedGoodsItem);
          });
        }
      },
      //表格增加/减少一行方法
      row (name, index) {
        if (name === 'push') {
          this.commissionForm.billGoodsDetailItems.splice(index + 1, 0, {
            commissionCalcType: 1
          })
        } else {
          if (this.commissionForm.billGoodsDetailItems.length <= 1) return
          this.commissionForm.billGoodsDetailItems.splice(index, 1)
        }
      },
      // 表单重置
      async reset () {
        const { rows } = await listlevel({
          pageNum: 1,
          pageSize: 9999999,
        });
        this.distributionLevelList = rows;
        this.commissionForm = {
          billGoodsDetailItems: [{ commissionCalcType: 1}], //销售提成表格
          distributionType:1,
          billLevelDetailItems: [], //适用会员表格数据
          vipLevelRangeType:'',
        }
        this.resetForm('form')
        this.getNewBillNo(this.commissionForm, "billNo", "150401");
      },
      /** 提交按钮 */
      async submitForm (bool,isAudit) {
        this.$refs['form'].validate(async valid => {
          if (valid) {
            //判断是生效时间是否小于失效时间
            const begTime = new Date(this.commissionForm.begTime)
            const endTime = new Date(this.commissionForm.endTime)
            if (begTime > endTime) {
              this.$message.error('生效时间不能小于失效时间')
              return
            }
            let obj = JSON.parse(JSON.stringify(this.commissionForm));
            obj.billLevelDetailItems = this.commissionForm.billLevelDetailItems.map(item => ({
              isExcl:this.commissionForm.vipLevelRangeType == 1,
              lineId:item.lineId,
              lineType:item.lineType
            }))
            obj.billDate = getTimeDate()
            let text = this.commissionForm.billId?'修改':'成功';
            let res = undefined;
            if (this.commissionForm.billId) {
              res =  await commissionUpdateAPI(obj)
            } else {
              res =  await commissionAddAPI(obj)
            }
            this.commissionForm.billId = res.data.billId;
            this.commissionForm.billStatus = res.data.billStatus;

            if(bool){
              this.reset();
              this.commissionForm.billDate = getTimeDate()
              this.$modal.msgSuccess('保存并新增成功')
            }else{
              this.$modal.msgSuccess(text+'方案成功')
              if(isAudit){
                this.submitAudit();
              }else{
                this.$store.state.tagsView.visitedViews.splice(
                  this.$store.state.tagsView.visitedViews.findIndex(
                    item => item.path === this.$route.path
                  ),
                  1
                )
                this.$router.push('/vipcard/distribution/commissionCase')
                this.$destroy()
              }
            }
          }
        })
      },
      //审核/反审核按钮 type 0是反审核 2是审核
      async submitAudit(type) {
        let billIds = this.commissionForm.billId;
        //当id为空时,先保存再审核
        if (!billIds){
          billIds = await this.submitForm(false,true);
        }
        let typeName = type === "反审核" ? "反审核成功" : "审核成功";
        if (type === "审核" && this.form.billStatus != "0")
          return this.$message.error("此单据不是未审核的状态哦~");
        if (type === "反审核" && this.form.billStatus != "2")
          return this.$message.error("此单据不是已审核的状态哦~");
          if(!billIds){
            return;
          }
          updateBillStatus([billIds],type === "反审核" ? "0" : "2",'/api/system/vip/distribution/commission/updateBillStatus')
          .then((res) => {
            this.commissionForm = res.data;
            this.$message.success(typeName);
          });
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .wrap {
    padding: 10px 10px 0 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
    // 顶部按钮
    .btnTOP {
      text-align: right;
      padding: 0 10px 6px 0;
    }
    //基本信息内容
    .tableContentTop {
      padding: 6px 10px;
    }
    .inputWidthM{
      width:200px;
    }
    //表格
    .tableContent {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
  //表单原生样式
  ::v-deep .el-form-item {
    margin: 0;
    padding: 0;
  }
  .vipBtn{
    margin-bottom:10px;
  }
  .sWidth{
    width:150px!important;
  }
  </style>
  