<!-- 分销员 -->
<template>
    <div class="wrap x-x" id="wrap">
      <div class="left">
        <!-- 左边树装选择 -->
        <categoryTreeCard
          categoryTreeTitle="分销员等级"
          @treeNodeId="getTreeNodeId"
          @appendTree="appendTree"
          @editTree="editTree"
          @removeTree="removeTree"
          :isEditBtn="true"
          :boxHeight="boxHeight"
          :boxInternalHeight="boxInternalHeight"
          :categoryTreeOptions="distributionManLevelOptions"
          :defaultProps="defaultProps"
        />
      </div>
      <div class="right">
        <TablePage
          ref="tablePage"
          class="tablepage"
          v-model="options"
          @handleEvent="handleEvent"
        >
        </TablePage>
      </div>
      
      <!-- 分销员结算 -->
      <c-dialog title="分销员结算" :width="700" :showDialog.sync="dialogSettlement">
        <template #content>
          <el-form :model="settlementForm" ref="settlementForm" :rules="settlementRules" label-width="120px"
            v-loading="loadingSettlementDia">
            <div class="x-x">
              <el-form-item label="分销员">
                <el-input disabled style="width: 170px" placeholder="分销员" v-model="settlementForm.distributionManVipNo" />
              </el-form-item>
              <el-form-item label="累计结算提成">
                <el-input disabled style="width: 170px" placeholder="累计结算提成"
                  v-model="settlementForm.totalSettlementCommission" />
              </el-form-item>
            </div>
            <div class="x-x">
              <el-form-item label="本次销售金额">
                <el-input disabled style="width: 170px" placeholder="本次销售金额" v-model="settlementForm.nowSaleMoney" />
              </el-form-item>
              <el-form-item label="本次销售提成">
                <el-input disabled style="width: 170px" placeholder="本次销售提成" v-model="settlementForm.nowSaleCommission" />
              </el-form-item>
            </div>
            <div class="x-x">
              <el-form-item label="未结算提成">
                <el-input disabled style="width: 170px" placeholder="未结算提成"
                  v-model="settlementForm.totalUsableCommission" />
              </el-form-item>
              <el-form-item label="本次结算" prop="nowSettlementMoney">
                <el-input style="width: 170px" placeholder="本次结算" v-model="settlementForm.nowSettlementMoney" />
              </el-form-item>
            </div>
          </el-form>
        </template>
        <template #buttonList>
          <el-button type="primary" @click="settlementConfirm">确定</el-button>
          <el-button @click="settlementCancel">取消</el-button>
        </template>
      </c-dialog>

      <!-- 操作会员级别弹窗 -->
      <levelDialog ref="levelDialog" @getList="getLevelData"></levelDialog>
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
  </template>
  
  <script>
  import Dialog from "@/components/Dialog";
  import ToChannel from "@/components/dialogTemplate/toChannel/index.vue";
  import levelDialog from "./components/levelDialog.vue";
  import categoryTreeCard from "@/views/components/categoryTreeCard"; //选择类别
  import TablePage from "@/components/tablePage";
  import CDialog from '@/components/dialogTemplate/dialog/index.vue'
  import { getToken } from "@/utils/auth";
  // 导入模板接口importTemplate
  import { importTemplate } from "@/api/system/user"; //导入接口
  import {
    listlevel,
    dellevel,
    listDistributionMan,
    delDistributionMan,
    getDistributionManDetail,
    restoreDistributionMan,
    DistributionManSettlementAPI,
    updateBillStatus
  } from "@/api/vip/distribution/man";
  import { deepCopy } from "@/utils";
  import { vipParamListAPI } from "@/api/shop/setup/posParam/vipParam";
  export default {
    name: "vipCard",
    dicts: ["vip_card_status", "sys_user_sex", "App_del_flag"],
    components: {
      categoryTreeCard,
      TablePage,
      CDialog,
      levelDialog,
      ToChannel,
      Dialog,
    },
    data() {
      return {
        vipBrandId: undefined, //会员品牌
        openToChannel: false, //会员资料导入开关
        downloadFile: {
          // 下载模板
          fileName: "会员资料导入模板.xlsx", // 文件名
          downloadUrl:
            "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-09-27/隼云商品导入示范文件.xlsx", // 下载地址
        },
        //树型组件参数
        defaultProps: {
          id: "distributionLevelId",
          label: "distributionLevelName",
        },
        options: {
          virtualScroll: true, // 虚拟滚动
          mutiSelect: true, // 多选
          loading: false,
          pagination: {
            total: 0,
            page: 1,
            size: 15,
          },
          getListApi: listDistributionMan,
          // getSummaryApi: vipInfoSummary,
          // exportOption: {},
          body: {},
          defaultBody: {},
          title: "分销员列表",
          listNo: true, // 序号
          check: [], // 选中数据
          rowKey: "distributionManVipId",
          //搜索
          search: [
            {
              label: "日期",
              type: "datetimerange",
              model: "",
              noReset: true,
              clearable: true, 
              filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
            },
            {
              keyIndex: 0,
              format: "datetimerange",
              type: "timeRange",
              defaultValue: "month",
              data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            },
            {
              type: 'filters',
              tip: '分销员编号/分销员名称',
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'distributionManVipNos', label: '分销员编号' },
                { filter: 'distributionManVipNames', label: '分销员名称' }
              ]
            },
            this.$select({
              key: "isStopState",
              option: { seniorSearch: true, option: { multiple: true } },
            }),
            {
              type: 'local',
              label: '是否删除',
              model: '',
              filter: 'delFlag',
              seniorSearch: true,
              option: {
                clearable: true,
                data: [
                  { label: '未删除', value: 0 },
                  { label: '已删除', value: 1 },
                ],
                label: 'label',
                value: 'value',
              }
            },
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
            
            {
              type: 'button',
              tip: '高级查询',
              btnType: 'primary',
              click: 'seniorSearch'
            },
            { type: 'button', tip: '重置', click: 'reset' }
          ],
          //按钮
          buttons: [
            {
              click: "settlement",
              label: "结算",
              // icon: "iconfont icon-jiesuan",
              type: "primary",
            },
            {
              click: "del",
              label: "删除",
              type: "danger",
              icon: "el-icon-delete",
              alertText: "确认要删除选中分销员吗？",
              btnType: "dropdown",
              trigger: "click", // 要求
              other: [
                {
                  label: "回收站",
                  collectionDialog: {
                    restoreListApi: restoreDistributionMan,
                    dcollectionBody: {
                      delFlag: "1",
                    },
                  },
                },
              ],
            },
            {
              click: "audit",
              label: "审核",
              type: "success",
              icon: "el-icon-document",
              disabled: () => !this.options?.check?.length,
            },
            {
              click: "refresh",
              right: true,
              label: "刷新",
              icon: "el-icon-refresh",
              type: "",
            },
          ],
          //表格
          columns: [
            {
              prop: "distributionManVipNo",
              label: "编号",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "distributionManVipName",
              label: "分销员名称",
              type: "link",
              click: "update",
              minWidth: 150,
              align: "center",
              fixed: true,
            },
            {
              prop: "nowSaleCommission",
              label: "本次销售提成",
              minWidth: 150,
              align: "center",
            },
            {
              prop: "nowSaleMoney",
              label: "本次销售金额",
              minWidth: 150,
              align: "center",
            },
            {
              prop: "nowSaleQty",
              label: "本次销售数量",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "startConsumeTime",
              label: "开始消费时间",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "lastConsumeTime",
              label: "最后消费时间",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "totalAllCommission",
              label: "总共应提提成",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "auditStatus",
              label: "状态",
              minWidth: 100,
              formatter: v => (v == 1 ? '未审核' : '已审核')
            },
            {
              prop: "totalSaleCommission",
              label: "累计销售提成",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "totalSaleMoney",
              label: "累计销售金额",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "totalSaleQty",
              label: "累计销售数量",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "totalSettlementCommission",
              label: "总共结算提成",
              minWidth: 120,
              align: "center",
            },
            {
              prop: "totalUsableCommission",
              label: "总共可提提成",
              minWidth: 110,
              align: "center",
            },
          ],
          summary: [
            'nowSaleCommission',
            'nowSaleMoney',
            'nowSaleQty',
            'totalAllCommission',
            'totalSaleCommission',
            'totalSaleMoney',
            'totalSaleQty',
            'totalSettlementCommission',
            'totalUsableCommission'
          ],
          list: [],
        },
        //弹窗配置
        dialogOptions: {
          curType: "", //当前类型(门店/商品/类别/品牌)
          title: "文件导入",
          width: 600,
          show: false,
          type: "ImportFile",
        },
        //当前会员级别
        curVipLevel: undefined, //当前会员级别
        vivwH: 0, //页面高度
        //高级搜索图标
        icon: "el-icon-caret-bottom",
        //是否显示高级搜索
        showHigh: false,
        isBirthday: undefined, //会员生日
        datetime: undefined, //单据查询时间
        title: "", // 弹出层标题
        ids: [], // 选中数组
        name: [], //选中数组的名称
        total: 0, // 总条数
        // 是否显示弹出层
        open: false,
        loading: false, //加载框
        tableData: [], //表格
        distributionManLevelOptions: [], //会员等级tree
  
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 15,
        },
        // 用户导入参数
        upload: {
          // 是否显示弹出层（用户导入）
          open: false,
          // 弹出层标题（用户导入）
          title: "",
          // 是否禁用上传
          isUploading: false,
          // 是否更新已经存在的用户数据
          updateSupport: 0,
          // 设置上传的请求头部
          headers: { Authorization: "Bearer " + getToken() },
          // 上传的地址
          url: process.env.VUE_APP_BASE_API + "/system/user/importData",
        },
        //结算表单
        settlementForm: {},
        loadingSettlementDia:false,
        dialogSettlement:false,
      };
    },
    created() {
      this.getLevelData();
    },
    computed: {
      //tree组件自定义高度
      boxHeight() {
        return this.vivwH - 10;
      },
      boxInternalHeight() {
        return this.vivwH - 83;
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.vivwH = document.getElementById("wrap").clientHeight;
      });
    },
    methods: {
      //会员资料导入
      uploadFile(fromData) {
        console.log(fromData, "fromData");
        // uploadFileRequest(fromData)
        //   .then(res => {
        //     this.$message.success('上传成功')
        //     this.openToChannel = false
        //     this.getList()
        //   })
        //   .catch(error => {
        //     console.log('error', error)
        //     this.$message.error('导入商品资料失败')
        //     this.errorList = error.data.errorList.map(item => {
        //       return { errorInfo: item }
        //     })
        //     this.errVisible = true
        //   })
      },
      handleResize() {
        this.vivwH = document.getElementById("wrap").clientHeight;
      },
      async handleEvent(type, row, e) {
        switch (type) {
          case "add":
            this.handleAdd();
            break;
          case "update":
            this.handleDetail(row);
            break;
          case "batchEdit":
            this.batchEdit();
            break;
          case "del":
            this.handleDelete();
            break;
          case "settlement":
            this.getSettlement();
            break;
            
          case "audit":
            {
              this.handleBillStatus("审核");
            }
            break;
          default:
            break;
        }
      },
      //点击会员级别
      getTreeNodeId(value, row) {
        this.options.defaultBody.distributionLevelId = value;
        this.vipBrandId = row?.vipBrandId;
        this.$refs.tablePage.getList();
      },
      //新增会员级别
      appendTree() {
        this.$refs.levelDialog.initializaData();
      },
      //编辑会员级别
      editTree(data) {
        this.$refs.levelDialog.initializaData(data.distributionLevelId);
      },
      //删除会员级别
      async removeTree({ node, data }) {
        this.$confirm("确定删除此分销员等级?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            try {
              await dellevel([data.distributionLevelId]);
              this.$message.success("删除分销员等级");
              this.getLevelData();
            } catch (err) {}
          })
          .catch(() => {});
      },
      /** 删除按钮操作 */
      handleDelete() {
        let name = [];
        let ids = [];
        this.options.check.map((item) => {
          name.push(item.distributionManVipName);
          ids.push(item.distributionManVipId);
        });
        this.$modal
          .confirm('是否确认删除会员名称为"' + name.join(",") + '"的数据项？')
          .then(async () => {
            try {
              await delDistributionMan(ids);
              this.$modal.msgSuccess("删除成功");
              this.$refs.tablePage.getList();
            } catch (err) {}
          })
          .catch(() => {});
      },
      /** 新增按钮操作 */
      handleAdd() {
        //重置表单
        this.$refs.addDialog.reset();
        //修改新增对话框标题
        this.$refs.addDialog.title = "新增会员资料";
        //会员级别
        let distributionManLevelOptions = deepCopy(this.distributionManLevelOptions);
        distributionManLevelOptions.shift();
        this.$refs.addDialog.distributionManLevelOptions = distributionManLevelOptions;
        this.$refs.addDialog.vipForm.distributionLevelId = this.options.defaultBody.distributionLevelId;
        this.$refs.addDialog.changeVipLevel(this.options.defaultBody.distributionLevelId);
        //弹出新增的对话框
        this.$refs.addDialog.open = true;
      },
      //编辑按钮
      async handleDetail(row) {
        //重置表单
        this.$refs.addDialog.reset();
        this.$refs.addDialog.title = "编辑会员资料";
        //弹出新增的对话框
        this.$refs.addDialog.open = true;
        //获取品牌详情
        this.$refs.addDialog.detailLoading = true;
        //会员级别
        let distributionManLevelOptions = deepCopy(this.distributionManLevelOptions);
        distributionManLevelOptions.shift();
        this.$refs.addDialog.distributionManLevelOptions = distributionManLevelOptions;
        try {
          const res = await getDistributionManDetail(row.distributionManVipId);
          const { data } = await vipParamListAPI(); //获取会员启用消费密码
          //处理会员生日农历
          if (res.data.isCnBirthday) {
            res.data.birthday = "L" + res.data.birthday;
          }
          this.$refs.addDialog.vipForm = {
            ...res.data,
            shop_VIP_CONFIG_PASSWORD_12: data.shop_VIP_CONFIG_PASSWORD_12,
            usePassWord: res.data.usePassWord ? "******" : "",
          };
          this.$refs.addDialog.cnCatch = true;
          this.$refs.addDialog.detailLoading = false;
        } catch (err) {}
      },
      //获取分销员级别数据
      async getLevelData() {
        try {
          const { rows } = await listlevel({
            pageNum: 1,
            pageSize: 9999999,
          });
          this.distributionManLevelOptions = rows;
          //if (this.distributionManLevelOptions.length <= 1) {
            //添加一个全部级别
            this.distributionManLevelOptions.unshift({
              distributionLevelId: "",
              distributionLevelName: "全部级别",
            });
          //}
        } catch (err) {}
      },
      /** 导入按钮操作 */
      handleImport() {
        this.upload.title = "用户导入";
        this.upload.open = true;
      },
      /** 下载模板操作 */
      importTemplate() {
        importTemplate().then((response) => {
          this.download(response.msg);
        });
      },
      //结算确定按钮
      async settlementConfirm() {
        if (parseFloat(this.settlementForm.nowSettlementMoney) <= 0) {
          this.$message.error('本次结算提成不能小于等于0')
          return
        }
        if (
          parseFloat(this.settlementForm.totalUsableCommission) <
          parseFloat(this.settlementForm.nowSettlementMoney)
        ) {
          this.$message.error('本次结算提成不能大于未结算提成')
          return
        }

        const res = await DistributionManSettlementAPI({
          distributionManVipId: this.settlementForm.distributionManVipId,
          nowSettlementMoney: this.settlementForm.nowSettlementMoney
        })
        this.$message({
          message: '结算成功',
          type: 'success'
        })
        this.dialogSettlement = false
        this.$refs.tablePage.getList();
      },
      //结算取消按钮
      settlementCancel() {
        this.dialogSettlement = false
        this.settlementForm = {}
        this.resetForm('settlementForm')
      },
      // 结算
      async getSettlement() {
        const selectData = this.options.check;
        if (selectData.length!=1) return this.$message.warning('请选择单个分销员进行结算')

        this.settlementForm = {}
        this.resetForm('settlementForm')
        this.dialogSettlement = true
        this.loadingSettlementDia = true
        const res = await getDistributionManDetail(selectData[0].distributionManVipId)
        this.settlementForm = res.data
        this.loadingSettlementDia = false
      },
      handleBillStatus(command) {
        const selectData = this.options.check;
        if (selectData.length==0) return this.$message.warning('请选择操作数据')
        let name = "";
        let billStatus = "";
        if (command === "反审核") {
          name = "反审核";
          billStatus = "0";
        } else {
          if (command === "审核") {
            name = "审核";
            billStatus = "2";
          }
        }
        const distributionManVipIds = selectData.map((i) => i.distributionManVipId);
        const billNo = selectData.map((i) => i.distributionManVipLevelNo);
        const $this = this;
        let str = billNo.length > 2 ? `${billNo[0]},${billNo[1]}...` : billNo;
        this.$modal
          // .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
          .confirm(`是否确认${name}分销员编号为"` + str + '"的单据项?')
          .then(function () {
            return updateBillStatus([...distributionManVipIds], billStatus).then(() => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              $this.$modal.msgSuccess(`${name}成功`);
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
            });
          });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .wrap {
    padding: 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
    ::v-deep .categoryTree {
      width: 240px !important;
    }
    ::v-deep .searchTree {
      width: 248px !important;
    }
    .right {
      width: calc(100% - 246px);
    }
  }
  ::v-deep #table-page {
    padding: 0;
  }
  ::v-deep .el-date-editor--datetimerange.el-input__inner {
    width: 300px !important;
  }
  ::v-deep .select-all-filter {
    width: 200px;
  }
  </style>
  