var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.commissionForm,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "btnTOP" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(false)
                    },
                  },
                },
                [_vm._v("保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm(true)
                    },
                  },
                },
                [_vm._v("保存并新增 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitAudit },
                },
                [_vm._v("审核 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push(
                        "/vipcard/distribution/commissionCase"
                      )
                    },
                  },
                },
                [_vm._v("退出")]
              ),
            ],
            1
          ),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.commissionForm.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContentTop x-x" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: {
                            size: "mini",
                            maxlength: "20",
                            disabled: "",
                          },
                          model: {
                            value: _vm.commissionForm.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billNo", $$v)
                            },
                            expression: "commissionForm.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案名称", prop: "billName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidthM",
                          attrs: {
                            size: "mini",
                            maxlength: "40",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.commissionForm.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billName", $$v)
                            },
                            expression: "commissionForm.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生效日期", prop: "begTime" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputWidthM",
                          attrs: {
                            type: "datetime",
                            placeholder: "选择生效日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            size: "mini",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.commissionForm.begTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "begTime", $$v)
                            },
                            expression: "commissionForm.begTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "tableContentTop x-x",
                    staticStyle: { "padding-bottom": "10px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "失效日期", prop: "endTime" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputWidthM",
                          attrs: {
                            type: "datetime",
                            placeholder: "选择失效日期",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            size: "mini",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.commissionForm.endTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "endTime", $$v)
                            },
                            expression: "commissionForm.endTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "分销模式", prop: "distributionType" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "marB10 inputWidthM",
                            attrs: {
                              placeholder: "请选择分销模式",
                              size: "mini",
                              disabled: _vm.disabled,
                            },
                            on: { change: _vm.goodsRangeTypeChange },
                            model: {
                              value: _vm.commissionForm.distributionType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.commissionForm,
                                  "distributionType",
                                  $$v
                                )
                              },
                              expression: "commissionForm.distributionType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "一级分销", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "二级分销", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticClass: "inputRemark inputWidthM",
                          attrs: {
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 5 },
                            size: "mini",
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.commissionForm.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.commissionForm, "billRemark", $$v)
                            },
                            expression: "commissionForm.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "提成类型" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent marL15" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "marB10" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "提成范围",
                                prop: "goodsRangeType",
                                "label-width": "80px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "marB10",
                                  attrs: {
                                    placeholder: "请选择提成范围",
                                    filterable: "",
                                    clearable: "",
                                    size: "mini",
                                    disabled: _vm.disabled,
                                  },
                                  on: { change: _vm.goodsRangeTypeChange },
                                  model: {
                                    value: _vm.commissionForm.goodsRangeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.commissionForm,
                                        "goodsRangeType",
                                        $$v
                                      )
                                    },
                                    expression: "commissionForm.goodsRangeType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部商品", value: 0 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "指定商品", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "指定类别", value: 2 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "指定品牌", value: 3 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.commissionForm.goodsRangeType != undefined
                        ? _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingTable,
                                  expression: "loadingTable",
                                },
                              ],
                              ref: "multipleTable",
                              staticClass: "marB10",
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.commissionForm.billGoodsDetailItems,
                                border: "",
                                "max-height": "500",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  type: "index",
                                  width: "80",
                                  "class-name": "allowDrag",
                                  align: "center",
                                },
                              }),
                              !_vm.disabled &&
                              _vm.commissionForm.goodsRangeType != 0
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "操作",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus operatePush",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.row(
                                                      "push",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-remove operateDel",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.row(
                                                      "del",
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      901523703
                                    ),
                                  })
                                : _vm._e(),
                              _vm.commissionForm.goodsRangeType != 0
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "类型",
                                      align: "center",
                                      prop: "lineType",
                                      width: "130",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("dict-tag", {
                                                attrs: {
                                                  options:
                                                    _vm.dict.type
                                                      .vip_applicable_commodities_appoint_type,
                                                  value: scope.row.lineType,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1794384631
                                    ),
                                  })
                                : _vm._e(),
                              _vm.commissionForm.goodsRangeType != 0
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "编码",
                                      width: "180",
                                      align: "center",
                                      prop: "lineNo",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "billGoodsDetailItems." +
                                                      scope.$index +
                                                      ".lineNo",
                                                    rules:
                                                      _vm.rules[
                                                        "billGoodsDetailItems.lineNo"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        disabled: _vm.disabled,
                                                      },
                                                      nativeOn: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.keyup(
                                                            "lineNo",
                                                            $event,
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: scope.row.lineNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "lineNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.lineNo",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-more more",
                                                        attrs: {
                                                          slot: "suffix",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.selectLineType,
                                                        },
                                                        slot: "suffix",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      881522395
                                    ),
                                  })
                                : _vm._e(),
                              _vm.commissionForm.goodsRangeType != 0
                                ? _c("el-table-column", {
                                    attrs: {
                                      label: "名称",
                                      align: "center",
                                      prop: "lineName",
                                      width: "130",
                                    },
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  label: "会员级别",
                                  align: "center",
                                  prop: "lineName",
                                  width: "170",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop:
                                                  "billGoodsDetailItems." +
                                                  scope.$index +
                                                  ".distributionLevelId",
                                                rules:
                                                  _vm.rules[
                                                    "billGoodsDetailItems.distributionLevelId"
                                                  ],
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "marR10",
                                                  staticStyle: {
                                                    width: "146px",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择会员级别",
                                                    clearable: "",
                                                    disabled: _vm.disabled,
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row
                                                        .distributionLevelId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "distributionLevelId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.distributionLevelId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.distributionLevelList,
                                                  function (dict) {
                                                    return _c("el-option", {
                                                      key: dict.distributionLevelId,
                                                      attrs: {
                                                        label:
                                                          dict.distributionLevelName,
                                                        value:
                                                          dict.distributionLevelId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  293122263
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "提成方式",
                                  width:
                                    _vm.commissionForm.distributionType === 2
                                      ? 490
                                      : 325,
                                  align: "center",
                                  prop: "commissionCalcType",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "x-x" },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "billGoodsDetailItems." +
                                                      scope.$index +
                                                      ".commissionCalcType",
                                                    rules:
                                                      _vm.rules[
                                                        "billGoodsDetailItems.commissionCalcType"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticClass: "marR10",
                                                      staticStyle: {
                                                        width: "146px",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择提成方式",
                                                        clearable: "",
                                                        disabled: _vm.disabled,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.commissionCalcTypeChange(
                                                            scope.row,
                                                            1
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .commissionCalcType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "commissionCalcType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.commissionCalcType",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label:
                                                            "销售提成率(%)",
                                                          value: 1,
                                                        },
                                                      }),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label:
                                                            "按数量提成(个)",
                                                          value: 2,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              scope.row.commissionCalcType == 1
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "billGoodsDetailItems." +
                                                          scope.$index +
                                                          ".commissionSaleRate1",
                                                        rules:
                                                          _vm.rules[
                                                            "billGoodsDetailItems.commissionSaleRate1"
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "inputWidth sWidth",
                                                        attrs: {
                                                          oninput:
                                                            "value=value.replace(/[^0-9.]/g,'')",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.disabled,
                                                          placeholder:
                                                            "一级销售提成率",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .commissionSaleRate1,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "commissionSaleRate1",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.commissionSaleRate1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              scope.row.commissionCalcType == 2
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "billGoodsDetailItems." +
                                                          scope.$index +
                                                          ".commissionSaleOneMoney1",
                                                        rules:
                                                          _vm.rules[
                                                            "billGoodsDetailItems.commissionSaleOneMoney1"
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "inputWidth sWidth",
                                                        attrs: {
                                                          oninput:
                                                            "value=value.replace(/[^0-9.]/g,'')",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.disabled,
                                                          placeholder:
                                                            "一级按数量提成",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .commissionSaleOneMoney1,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "commissionSaleOneMoney1",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.commissionSaleOneMoney1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              scope.row.commissionCalcType ==
                                                1 &&
                                              _vm.commissionForm
                                                .distributionType === 2
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "billGoodsDetailItems." +
                                                          scope.$index +
                                                          ".commissionSaleRate2",
                                                        rules:
                                                          _vm.rules[
                                                            "billGoodsDetailItems.commissionSaleRate2"
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "inputWidth sWidth",
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          oninput:
                                                            "value=value.replace(/[^0-9.]/g,'')",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.disabled,
                                                          placeholder:
                                                            "二级销售提成率",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .commissionSaleRate2,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "commissionSaleRate2",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.commissionSaleRate2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              scope.row.commissionCalcType ==
                                                2 &&
                                              _vm.commissionForm
                                                .distributionType === 2
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        prop:
                                                          "billGoodsDetailItems." +
                                                          scope.$index +
                                                          ".commissionSaleOneMoney2",
                                                        rules:
                                                          _vm.rules[
                                                            "billGoodsDetailItems.commissionSaleOneMoney2"
                                                          ],
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticClass:
                                                          "inputWidth sWidth",
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          oninput:
                                                            "value=value.replace(/[^0-9.]/g,'')",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.disabled,
                                                          placeholder:
                                                            "二级按数量提成",
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .commissionSaleOneMoney2,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "commissionSaleOneMoney2",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.commissionSaleOneMoney2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1346715684
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { align: "center", prop: "goodsNo" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "适用会员",
                            prop: "vipLevelRangeType",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.disabled },
                              model: {
                                value: _vm.commissionForm.vipLevelRangeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.commissionForm,
                                    "vipLevelRangeType",
                                    $$v
                                  )
                                },
                                expression: "commissionForm.vipLevelRangeType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("全部会员"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("全部会员+排除"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("指定会员"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.vipTable
                        ? _c(
                            "div",
                            { staticClass: "selectVip" },
                            [
                              _c(
                                "div",
                                { staticClass: "vipBtn" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                      },
                                      on: { click: _vm.openSelectVip },
                                    },
                                    [_vm._v("选择会员 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled: _vm.disabled,
                                      },
                                      on: { click: _vm.openSelectVipRank },
                                    },
                                    [_vm._v("选择会员等级 ")]
                                  ),
                                ],
                                1
                              ),
                              _c("comTable", {
                                staticClass: "marB10",
                                attrs: {
                                  columnLabel: _vm.vipTableLable,
                                  tableData:
                                    _vm.commissionForm.billLevelDetailItems,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  delTableRow: function ($event) {
                                    return _vm.delTableRow($event, "vip")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "tableMiddle",
                                      fn: function () {
                                        return [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "类型",
                                              align: "center",
                                              prop: "lineType",
                                              width: "123",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("dict-tag", {
                                                        attrs: {
                                                          options:
                                                            _vm.dict.type
                                                              .member_type,
                                                          value:
                                                            scope.row.lineType,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              889154850
                                            ),
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1129299638
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("selectVipRank", {
        attrs: { OpenVipRank: _vm.OpenVipRank },
        on: {
          "update:OpenVipRank": function ($event) {
            _vm.OpenVipRank = $event
          },
          "update:open-vip-rank": function ($event) {
            _vm.OpenVipRank = $event
          },
          getVipRank: function ($event) {
            return _vm.getPitchData($event, "vipRank")
          },
        },
      }),
      _c("selectVips", {
        attrs: { OpenVipCards: _vm.OpenVipCards },
        on: {
          "update:OpenVipCards": function ($event) {
            _vm.OpenVipCards = $event
          },
          "update:open-vip-cards": function ($event) {
            _vm.OpenVipCards = $event
          },
          getVipS: function ($event) {
            return _vm.getPitchData($event, "vipItem")
          },
        },
      }),
      _c("selectGoods", {
        attrs: { OpenGoods: _vm.openGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.openGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.openGoods = $event
          },
          getGoodsIds: _vm.getGoodS,
        },
      }),
      _c("selectCategory", {
        attrs: {
          OpenCategory: _vm.openCategory,
          queryCode: _vm.queryCodeCategory,
        },
        on: {
          "update:OpenCategory": function ($event) {
            _vm.openCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.openCategory = $event
          },
          categoryList: _vm.categoryList,
        },
      }),
      _c("selectBrand", {
        attrs: { OpenBrand: _vm.openBrand, queryCode: _vm.queryCodeBrand },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.openBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.openBrand = $event
          },
          brandList: _vm.brandList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }