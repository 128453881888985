<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  vipFillListAPI,
  vipFillListSummaryAPI,
  cashierListAPI,
  cashierListSummaryAPI,
} from "@/api/vip/report/fillQuery"; //会员充值接口

//业务类型枚举
let businessType = [
  { value: "0", label: "后台充值" },
  { value: "1", label: "前台POS充值" },
  { value: "2", label: "前台积分转充值" },
  { value: "3", label: "后台积分转充值" },
  { value: "4", label: "线上充值" },
  { value: "5", label: "门店换卡充值" },
  { value: "6", label: "导入储值" },
  { value: "8", label: "储零" },
];
export default {
  name: "fillQuery",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "会员充值查询",
        rowKey: "id",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "级别编码/级别名称/会员卡号/会员名称/手机号",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
            ],
          },
          this.$select({
            key: "viplevel",
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          // {
          //   type: "input",
          //   label: "充值金额",
          //   model: "",
          //   filter: "fillMoney",
          //   seniorSearch: true,
          // },
          {
            type: "numberRange",
            label: "金额范围",
            filter: "scoreBegin",
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filterFnc: ([one, two] = []) => ({
              beginFillMoney: one,
              endFillMoney: two,
            }),
          },
          {
            label: "充值类型",
            seniorSearch: true,
            type: "local",
            model: "", // 会员级别、生日类型：月份、日期（如下图）
            filter: "fillTypes",
            option: {
              data: businessType,
              label: "label",
              value: "value",
              filterable: true,
              multiple: true,
            },
          },
          this.$select({
            key: "listCashier",
            option: {
              filter: "cashierIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            type: "input",
            label: "备注",
            model: "",
            filter: "billRemark",
            seniorSearch: true,
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "detail",
        tabsColumns: [
          {
            title: "会员充值查询明细",
            type: "detail",
            getListApi: vipFillListAPI,
            getSummaryApi: vipFillListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipFillQuery",
            //   exportName: "会员充值查询明细",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListFillDetailExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listFillDetailExport",
              exportName: "会员充值查询明细",
            },
            columns: [
              {
                prop: "billNo",
                label: "充值小票号",
                minWidth: 180,
              },
              {
                prop: "fillTime",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "cashierNo",
                label: "收银员编码",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 150,
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "tel",
                label: "手机号",
                minWidth: 130,
              },
              {
                prop: "cardStatusName",
                label: "卡状态",
                minWidth: 100,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessScore",
                label: "赠送积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "endMoney",
                label: "充值后余额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "fillMoneyTotal",
                label: "充值总额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "billRemark",
                label: "备注",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "payTypes",
                type: "slots",
                align: "center",
                formatter: () => "",
                label: "收款方式",
                minWidth: 300,
                children: () =>
                  this.options.list?.[0]?.payTypes
                    ?.map?.((x) => ({
                      ...x,
                      label: x.payModeName,
                      prop: `payDetailList.${x.payModeId}.payModeId.payMoney`,
                      minWidth: 110,
                      summary: true,
                      getValue: (row, prop) =>
                        row.payDetailList?.find((y) => y.payModeId === x.payModeId)
                          ?.payMoney || 0,
                    }))
                    .sort((a, b) => b.payModeId - a.payModeId) || [],
              },
              {
                prop: "fillTypeName",
                label: "充值类型",
                minWidth: 150,
              },
            ],
            summary: [
              "fillMoney",
              "largessMoney",
              "largessScore",
              "endMoney",
              "fillMoneyTotal",
            ],
          },
          {
            title: "会员充值收款查询（按收银员汇总）",
            type: "cashier",
            getListApi: cashierListAPI,
            getSummaryApi: cashierListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipCashierQuery",
            //   exportName: "会员充值收款查询（按收银员汇总）",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListCashierExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listCashierExport",
              exportName: "会员充值收款查询（按收银员汇总）",
            },
            defaultBody: { summaryType: 1 },
            columns: [
              {
                prop: "fillTime",
                label: "日期",
                minWidth: 80,
              },
              {
                prop: "cashierNo",
                label: "收银员编号",
                minWidth: 80,
              },
              {
                prop: "cashierName",
                label: "收银员名称",
                minWidth: 80,
              },
              {
                prop: "payModeName",
                label: "收款方式",
                minWidth: 80,
              },
              {
                prop: "payMoney",
                label: "收款金额",
                minWidth: 80,
              },
            ],
            summary: ["payMoney"],
          },
          {
            title: "会员充值查询（按会员卡汇总）",
            type: "vip",
            getListApi: cashierListAPI,
            getSummaryApi: cashierListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipCashierQuery",
            //   exportName: "会员充值查询（按会员卡汇总）",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListCashierExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listCashierExport",
              exportName: "会员充值查询（按会员卡汇总）",
            },
            defaultBody: { summaryType: 2 },
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessScore",
                label: "赠送积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "fillMoneyTotal",
                label: "充值总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["fillMoney", "largessMoney", "largessScore", "fillMoneyTotal"],
          },
          {
            title: "会员充值查询（按门店汇总）",
            type: "shop",
            getListApi: cashierListAPI,
            getSummaryApi: cashierListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipCashierQuery",
            //   exportName: "会员充值查询（按门店汇总）",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListCashierExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listCashierExport",
              exportName: "会员充值查询（按门店汇总）",
            },
            defaultBody: { summaryType: 3 },
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                sortable: "custom",
                minWidth: 150,
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessScore",
                label: "赠送积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "fillMoneyTotal",
                label: "充值总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["fillMoney", "largessMoney", "largessScore", "fillMoneyTotal"],
          },
          {
            title: "会员充值查询（按日期汇总）",
            type: "date",
            getListApi: cashierListAPI,
            getSummaryApi: cashierListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipCashierQuery",
            //   exportName: "会员充值查询（按日期汇总）",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListCashierExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listCashierExport",
              exportName: "会员充值查询（按日期汇总）",
            },
            defaultBody: { summaryType: 4 },
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 80,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessScore",
                label: "赠送积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "fillMoneyTotal",
                label: "充值总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["fillMoney", "largessMoney", "largessScore", "fillMoneyTotal"],
          },
          {
            title: "会员充值查询（按会员级别汇总）",
            type: "vipLevel",
            getListApi: cashierListAPI,
            getSummaryApi: cashierListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipCashierQuery",
            //   exportName: "会员充值查询（按会员级别汇总）",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListCashierExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listCashierExport",
              exportName: "会员充值查询（按会员级别汇总）",
            },
            defaultBody: { summaryType: 5 },
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "fillMoney",
                label: "充值金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessMoney",
                label: "赠送金额",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "largessScore",
                label: "赠送积分",
                minWidth: 120,
                sortable: true,
              },
              {
                prop: "fillMoneyTotal",
                label: "充值总额",
                minWidth: 120,
                sortable: true,
              },
            ],
            summary: ["fillMoney", "largessMoney", "largessScore", "fillMoneyTotal"],
          },
          {
            title: "充值赠送优惠券查询",
            type: "coupon",
            getListApi: cashierListAPI,
            getSummaryApi: cashierListSummaryAPI,
            // exportOption: {
            //   exportApi: "vipCashierQuery",
            //   exportName: "会员充值查询明细",
            // },
            exportOption: {
              ossKeyUrl: "api/system/vip/report/Fill/query/getListCashierExport",
              fastExportUrl: "api/system/vip/report/Fill/query/listCashierExport",
              exportName: "会员充值查询明细",
            },
            defaultBody: { summaryType: 6 },
            columns: [
              {
                prop: "fillTime",
                label: "日期",
                minWidth: 155,
              },
              {
                prop: "billNo",
                label: "充值小票号",
                minWidth: 180,
              },
              {
                prop: "shopName",
                label: "门店",
                minWidth: 150,
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 150,
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
              },
              {
                prop: "couponCaseTypeName",
                label: "优惠券类型",
                minWidth: 120,
              },
              {
                prop: "couponCaseName",
                label: "优惠券名称",
                minWidth: 150,
              },
              {
                prop: "couponNo",
                label: "优惠券号",
                minWidth: 180,
              },
              {
                prop: "couponStatusName",
                label: "优惠券状态",
                minWidth: 120,
              },
            ],
          },
        ],
      },
      payTypes: [], //付款方式
    };
  },
  watch: {
    options: {
      handler(val) {
        console.log(val, "valu");
      },
      deep: true,
    },
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "getListed":
          if (this.options.list?.length && this.options.tabColumnType === "vipFillList") {
            this.payTypes = this.options.list[0].payTypes;
            this.options.summary = [
              "fillMoney",
              "largessMoney",
              ...this.payTypes.map(
                (x) => `payDetailList.${x.payModeId}.payModeId.payMoney`
              ),
            ];
          }
          break;
        case "sortChange":
          this.options.defaultBody = {
            ...this.options.defaultBody,
            orderStr: row.prop,
            orderSort: row.order,
          };
          this.$refs.tablePage.getList();
          break;
        default:
          break;
      }
    },
  },
};
</script>
