<!-- 分销员提成结算 -->
<template>
    <div class="wrap">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </div>
  </template>
  <script>
  import TablePage from "@/components/tablePage";
  import { distributionSettlement } from "@/api/vip/distribution/commissionSettle"; //分销员子会员查询
  
  export default {
    name: "room",
    components: { TablePage },
    data() {
      return {
        options: {
          summary: [
            "unitQty",
            "unitTaxMoney",
            "unitPriceTaxMoney",
          ],
          mutiSelect: false, // 多选
          loading: true,
          pagination: {
            total: 0,
            page: 1,
            size: 10,
          },
          getListApi: distributionSettlement,
          //getSummaryApi: sumDetailReportForms,
          body: {},
          listNo: true, // 序号
          title: "分销员提成结算",
          check: [], // 选中数据
          rowKey: "1703967014381789184",
        //   exportOption: {
        //     show: true,
        //     exportApi: 'exportDetailReportForms',
        //     exportName: '采购明细表',
        //     timeout: 60*5*1000
        //   },
          search: [
            {
              label: "单据日期",
              type: "datetimerange",
              model: "",
              filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
            },
            {
              keyIndex: 0,
              format: "datetimerange",
              type: "timeRange",
              defaultValue: "day",
              data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
            },
            {
              type: 'filters',
              tip: '分销员编号/分销员名称',
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'distributionManVipNos', label: '分销员编号' },
                { filter: 'distributionManVipNames', label: '分销员名称' }
              ]
            },
            { type: "button", tip: "查询", btnType: "primary", click: "search" },
            // {
            //   type: "button",
            //   tip: "高级查询",
            //   btnType: "primary",
            //   click: "seniorSearch",
            // },
            { type: "button", tip: "重置", click: "reset" },
          ],
          columns: [
            {
              prop: "vipNo",
              label: "会员编号",
              minWidth: 130,
            },
            {
              prop: "vipName",
              label: "会员名称",
              minWidth: 130,
            },
            {
              prop: "vipLevelNo",
              label: "会员等级编号",
              minWidth: 120,
            },
            {
              prop: "vipLevelName",
              label: "会员等级名称",
              minWidth: 160,
            },
            {
              prop: "shopNo",
              label: "门店编号",
              minWidth: 130,
            },
            {
              prop: "shopName",
              label: "门店名称",
              minWidth: 130,
            },
            {
              prop: "endCommission",
              label: "剩余提成",
              minWidth: 130,
            },
            {
              prop: "optCommission",
              label: "操作提成",
              minWidth: 130,
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 130,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 120,
            },
            {
              prop: "createTime",
              label: "创建时间",
              minWidth: 120,
            },
          ],
          list: [],
        },
      };
    },
    methods: {
      seniorSearch() {},
    },
  };
  </script>
  <style lang="scss" scoped></style>
  