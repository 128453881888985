<template>
  <!-- 操作会员等级弹窗(新增/编辑) -->
  <div class="vipLevelDialog">
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="openDialog">
      <template #content>
        <div v-loading="loading">
          <el-form :model="levelForm" :rules="rules" ref="form" label-width="130px">
            <el-form-item label="分销员等级编码" prop="distributionLevelNo" key="distributionLevelNo" class="w100">
              <el-input
                class="vipInput"
                v-model="levelForm.distributionLevelNo"
                placeholder="请输入分销员等级编码(1-20字符)"
                maxlength="20"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="分销员级别名称" prop="distributionLevelName" key="distributionLevelName" class="w100">
              <el-input
                class="vipInput"
                v-model="levelForm.distributionLevelName"
                placeholder="请输入分销员级别名称(1-40字符)"
                maxlength="40"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="设置升级规则"
              prop="shopRangeType"
              class="w100"
            >
              <div class="ruleForm">
                <el-checkbox v-model="levelForm.puchaseAmount"
                  >自购金额</el-checkbox>
                <div class="flex">
                  <span>满</span>
                  <el-input
                      type="number"
                      :disabled="!levelForm.puchaseAmount"
                      oninput="value=value.replace(/^0|[^0-9]/g,'')"
                      v-model="levelForm.puchaseAmountNumber"
                      class="inputNumberW"
                      size="small">
                  </el-input>
                  <span>元</span>
                </div>
              </div><div class="ruleForm">
                <el-checkbox v-model="levelForm.promotionAmount"
                  >推广金额</el-checkbox>
                <div class="flex">
                  <span>满</span>
                  <el-input
                      :disabled="!levelForm.promotionAmount"
                      oninput="value=value.replace(/^0|[^0-9]/g,'')"
                      v-model="levelForm.promotionAmountNumber"
                      class="inputNumberW"
                      size="small">
                  </el-input>
                  <span>元</span>
                </div>
              </div><div class="ruleForm">
                <el-checkbox v-model="levelForm.developMember"
                  >发展会员数</el-checkbox>
                <div class="flex">
                  <span>满</span>
                  <el-input
                      :disabled="!levelForm.developMember"
                      oninput="value=value.replace(/^0|[^0-9]/g,'')"
                      v-model="levelForm.developMemberNumber"
                      class="inputNumberW"
                      size="small">
                  </el-input>
                  <span>人</span>
                </div>
              </div><div class="ruleForm">
                <el-checkbox v-model="levelForm.inviteDistributors"
                  >邀请分销员</el-checkbox>
                <div class="flex">
                  <span>满</span>
                  <el-input
                      :disabled="!levelForm.inviteDistributors"
                      oninput="value=value.replace(/^0|[^0-9]/g,'')"
                      v-model="levelForm.inviteDistributorsNumber"
                      class="inputNumberW"
                      size="small">
                  </el-input>
                  <span>人</span>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="是否默认级别" prop="isDefaultLevel" key="isDefaultLevel" class="w100">
              <el-switch
                v-model="levelForm.isDefaultLevel"
              ></el-switch>
            </el-form-item>
            <el-form-item label="排序" prop="sortNo" key="sortNo" class="w100">
              <el-input
                class="vipInput"
                v-model="levelForm.sortNo"
                placeholder="请输入排序"
                maxlength="40"
                oninput="value=value.replace(/^0|[^0-9]/g,'')"
                size="mini"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注" key="remark" class="w100">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                v-model="levelForm.remark"
                placeholder="请输入备注(1-80字符)"
                maxlength="80"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template #buttonList>
        <el-button class="marL10" @click="openDialog = false">取消</el-button>
        <el-button type="primary" class="marL10" @click="addlevel(true)"
          >保存并新增</el-button
        >
        <el-button type="primary" class="marL10" @click="addlevel(false)">保存</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import { priceCaseListAPI } from "@/api/system/goodsPrice/priceCase"; //商品价格方案
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { addlevel, updatelevel, getlevelDetail } from "@/api/vip/distribution/man";
import { getFormattedDate } from "@/utils/newDate";
export default {
  name: "VipLevelDialog",
  components: {
    CDialog,
    SelectLocal: () => import("@/components/tablePage/select/select-local/index.vue"),
  },
  props: {
    // vipBrandId: {
    //   type: [String, Number],
    //   default: "",
    // },
    // isWxVipCard: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      loading: false,
      title: "新增会员级别",
      dialogFormVisible: true,
      openDialog: false, //弹窗开关
      priceCaseList: [], //价格方案
      //会员级别表单
      levelForm: {
        distributionLevelName: "",
        distributionLevelNo: "",
        remark: "",
        sortNo:0,
        isDefaultLevel:false,
        puchaseAmount:false,
        promotionAmount:false,
        developMember:false,
        inviteDistributors:false,
        puchaseAmountNumber:'',
        promotionAmountNumber:'',
        developMemberNumber:'',
        inviteDistributorsNumber:'',
      },
      //表单校验
      rules: {
        distributionLevelName: [
          {
            required: true,
            message: "请输入分销员等级名称",
            trigger: "blur",
          },
        ],
        distributionLevelNo: [
          {
            required: true,
            message: "请输入分销员等级编码",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    //初始化弹窗数据
    async initializaData(distributionLevelId) {
      try {
        this.loading = true;
        this.reset();
        this.openDialog = true;
        const res = await priceCaseListAPI({
          pageNum: 1,
          pageSize: 9999,
        });
        this.priceCaseList = res.rows;
        if (distributionLevelId) {
          let { data } = await getlevelDetail(distributionLevelId);
          this.levelForm = data;
          this.levelForm.isDefaultLevel = data.isDefaultLevel?true:false;
          if(data.vipDistributionLevelRuleReqs?.length>0){
            this.$set(this.levelForm,'puchaseAmount',data.vipDistributionLevelRuleReqs[0].isUseRule);
            this.$set(this.levelForm,'puchaseAmountNumber',data.vipDistributionLevelRuleReqs[0].ruleValue);
            this.$set(this.levelForm,'promotionAmount',data.vipDistributionLevelRuleReqs[1].isUseRule);
            this.$set(this.levelForm,'promotionAmountNumber',data.vipDistributionLevelRuleReqs[1].ruleValue);
            this.$set(this.levelForm,'developMember',data.vipDistributionLevelRuleReqs[2].isUseRule);
            this.$set(this.levelForm,'developMemberNumber',data.vipDistributionLevelRuleReqs[2].ruleValue);
            this.$set(this.levelForm,'inviteDistributors',data.vipDistributionLevelRuleReqs[3].isUseRule);
            this.$set(this.levelForm,'inviteDistributorsNumber',data.vipDistributionLevelRuleReqs[3].ruleValue);
          }
          this.title = "编辑分销员等级";
        } else {
          this.title = "新增分销员等级";
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    //新增、修改对话框确认按钮
    async addlevel(isAdd) {
      try {
        await this.$refs["form"].validate();
        if(this.levelForm.puchaseAmount&&!this.levelForm.puchaseAmountNumber){
          this.$message({
            message: "自购金额不能为空",
            type: "warning",
          });
          return;
        }
        if(this.levelForm.promotionAmount&&!this.levelForm.promotionAmountNumber){
          this.$message({
            message: "推广金额不能为空",
            type: "warning",
          });
          return;
        }
        if(this.levelForm.developMember&&!this.levelForm.developMemberNumber){
          this.$message({
            message: "发展会员数不能为空",
            type: "warning",
          });
          return;
        }
        if(this.levelForm.inviteDistributors&&!this.levelForm.inviteDistributorsNumber){
          this.$message({
            message: "邀请分销员不能为空",
            type: "warning",
          });
          return;
        }
        this.loading = true;
        let vipDistributionLevelRuleReqs = [
          {
            "isUseRule": this.levelForm.puchaseAmount,
            "ruleType": 1,
            "ruleValue": this.levelForm.puchaseAmountNumber
          },
          {
            "isUseRule":this.levelForm.promotionAmount,
            "ruleType": 2,
            "ruleValue":this.levelForm.promotionAmountNumber
          },
          {
            "isUseRule": this.levelForm.developMember,
            "ruleType": 3,
            "ruleValue":  this.levelForm.developMemberNumber
          },
          {
            "isUseRule": this.levelForm.inviteDistributors,
            "ruleType": 4,
            "ruleValue": this.levelForm.inviteDistributorsNumber
          },
        ];
        let form = {
          distributionLevelNo:this.levelForm.distributionLevelNo,
          distributionLevelName:this.levelForm.distributionLevelName,
          distributionLevelId:this.levelForm.distributionLevelId||'',
          sortNo:this.levelForm.sortNo,
          isDefaultLevel:this.levelForm.isDefaultLevel,
          remark:this.levelForm.remark,
          vipDistributionLevelRuleReqs:vipDistributionLevelRuleReqs
        }
        if (this.levelForm.distributionLevelId != null) {
          await updatelevel({
            ...form,
          });
          this.$message({
            message: "修改分销员等级成功~",
            type: "success",
          });
        } else {
          await addlevel({
            ...form
          });
          this.$message({
            message: "新增分销员等级成功~",
            type: "success",
          });
        }
        if (isAdd) {
          this.reset();
          this.title = "新增分销员等级";
        } else {
          this.openDialog = false;
        }
        this.loading = false;
        this.$emit("getList");
      } catch (error) {
        this.loading = false;
      }
    },
    // 表单重置
    reset() {
      this.levelForm = {
        distributionLevelName: "",
        distributionLevelNo: "",
        remark: "",
        sortNo:0,
        isDefaultLevel:false,
        puchaseAmount:false,
        promotionAmount:false,
        developMember:false,
        inviteDistributors:false,
        puchaseAmountNumber:'',
        promotionAmountNumber:'',
        developMemberNumber:'',
        inviteDistributorsNumber:'',
      };
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
.vipLevelDialog {
  .el-form {
    margin: 15px 15px 0;
  }
}
.el-form-item {
  width: 50% !important;
  .el-select,
  .el-input,
  .el-input-number {
    width: 100%;
  }
  &.w100 {
    width: 100% !important;
  }
}
.ruleForm{
  display:flex;
  justify-content: space-between;
}
.flex{
  display:flex;
}
.inputNumberW{
  width:130px!important;
  margin:0 5px;
}
</style>
