<template>
  <div class="wrap">
    <!--会员概览  -->
    <el-card>
      <div>
        <div class="distributionTitleText">
          <span>会员概览</span>
        </div>
        <div class="x-bc marT30 marB30">
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.totalQty : "Loading" }}
            </div>
            <div class="label">会员总数</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.totalMoney : "Loading" }}
            </div>
            <div class="label">会员总余额</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.totalScore : "Loading" }}
            </div>
            <div class="label">会员总积分</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.customerUnitPrice : "Loading" }}
            </div>
            <div class="label">会员客单价</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.customerOrdersQty : "Loading" }}
            </div>
            <div class="label">会员客单数</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.allFillMoney : "Loading" }}
            </div>
            <div class="label">累计充值金额</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.allLargessMoney : "Loading" }}
            </div>
            <div class="label">累计赠送金额</div>
          </div>
          <div class="box">
            <div class="num">
              {{ overviewData ? overviewData.allConsumeMoney : "Loading" }}
            </div>
            <div class="label">累计消费金额</div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 会员同比分析 -->
    <el-card class="marT10">
      <div>
        <div class="x-bc">
          <div class="distributionTitleText">
            <span>会员同比分析</span>
          </div>
          <div>
            <div>
              <el-radio-group
                size="mini"
                v-model="selectedTimeFilter"
                @change="fetchOverviewData('vipYOY')"
              >
                <el-radio-button label="thisWeek">本周</el-radio-button>
                <el-radio-button label="thisMonth">本月</el-radio-button>
                <el-radio-button label="thisYear">本年</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div class="x-bc marT30 marB30">
          <div
            v-for="(item, index) in statisticsData"
            :key="index"
            class="top marL10 padd10 y-f-j"
          >
            <div class="y-start marL15">
              <div>{{ item.label }}: {{ item.value }}</div>
            </div>
            <div class="y-c">
              <div class="info2 x-bc">
                <!-- 同比增长 -->
                <div>
                  <div
                    class="x-f"
                    :style="`color:${item.yoyGrowth >= 0 ? '#ff2d46' : '#56cc56'}`"
                  >
                    {{ item.yoyGrowth }}%
                    <iconpark-icon
                      :name="getArrowIcon(item.yoyGrowth)"
                      v-show="item.yoyGrowth != 0"
                    ></iconpark-icon>
                  </div>
                  <div class="rechargeRace">同比增长</div>
                </div>
                <div class="dashed-line"></div>
                <!-- 环比增长 -->
                <div class="infoRight">
                  <div
                    class="x-f"
                    :style="`color:${item.momGrowth >= 0 ? '#ff2d46' : '#56cc56'}`"
                  >
                    {{ item.momGrowth }}%
                    <iconpark-icon
                      :name="getArrowIcon(item.momGrowth)"
                      v-show="item.momGrowth != 0"
                    ></iconpark-icon>
                  </div>
                  <div class="rechargeRace">环比增长</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div class="styleBox x-bc">
      <!-- 会员类型分布 -->
      <el-card class="marT10 styleLeft">
        <div>
          <div class="distributionTitleText">
            <span>会员类型分布</span>
          </div>
          <div class="x-f">
            <div class="y-start marT15">
              <div class="marT10">
                <div class="bgc">
                  <div class="data">{{ overviewData.totalQty }}</div>
                  <div class="recharge">会员总数(人)</div>
                </div>
                <div class="bgc">
                  <div class="data">{{ overviewData.onlineQty }}</div>
                  <div class="recharge">线上会员总数(人)</div>
                </div>
                <div class="bgc">
                  <div class="data">{{ overviewData.offlineQty }}</div>
                  <div class="recharge">线下会员总数(人)</div>
                </div>
              </div>
            </div>
            <div id="chart-style" style="width: 100%; height: 300px" />
          </div>
        </div>
      </el-card>
      <!-- 会员性别分析 -->
      <el-card class="marT10 styleRight">
        <div>
          <div class="x-bc">
            <div class="distributionTitleText">
              <span>会员性别分析</span>
            </div>
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo marL30"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">人数</el-menu-item>
              <el-menu-item index="2">金额</el-menu-item>
            </el-menu>
          </div>

          <div class="x-f">
            <div id="chart-sex-right" style="width: 100%; height: 300px" />
            <div class="y-end marT5">
              <div class="">
                <div class="bgc">
                  <div class="data">{{ manData }}</div>
                  <div class="recharge">
                    {{ `男性${activeIndex == "1" ? "(人)" : "元"}` }}
                  </div>
                </div>
                <div class="bgc">
                  <div class="data">{{ maleData }}</div>
                  <div class="recharge">
                    {{ `女性${activeIndex == "1" ? "(人)" : "元"}` }}
                  </div>
                </div>
                <div class="bgc">
                  <div class="data">{{ unknowData }}</div>
                  <div class="recharge">
                    {{ `未知占比${activeIndex == "1" ? "(人)" : "元"}` }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 消费趋势分析 -->
    <el-card class="marT10">
      <div>
        <div class="x-bc">
          <div class="distributionTitleText">
            <span>消费趋势分析</span>
          </div>
          <div>
            <div>
              <el-date-picker
                @change="fetchOverviewData('vipConsumer')"
                v-model="pickerTime"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
              >
              </el-date-picker>
              <timeRange
                format="daterange"
                default-value="week"
                v-model="pickerTime"
                size="mini"
                :data="['week', 'month', 'year']"
                @change="handleTimeRangeChange"
              />
            </div>
          </div>
        </div>
        <div id="chart-container" style="width: 100%; height: 420px"></div>
      </div>
    </el-card>
    <div class="styleBox x-bc">
      <!-- 年龄分布与消费金额 -->
      <el-card class="marT10 styleLeftAge">
        <div>
          <div class="x-bc">
            <div class="distributionTitleText">
              <span>年龄分布与消费金额</span>
            </div>
            <div class="x-bc">
              <div class="marL30">
                <el-menu
                  :default-active="activeIndexAge"
                  class="el-menu-demo"
                  mode="horizontal"
                  @select="handleSelectAge"
                >
                  <el-menu-item index="1">人数</el-menu-item>
                  <el-menu-item index="2">金额</el-menu-item>
                </el-menu>
              </div>
              <div
                id="myDiv2"
                style="
                  color: #409eff;
                  cursor: pointer;
                  margin-left: 30px;
                  margin-bottom: 15px;
                  font-size: 15px;
                "
                @click="dialogTableVisible = true"
              >
                设置
              </div>
            </div>
          </div>

          <div class="x-b">
            <div id="chart-age-right" style="width: 500px; height: 400px" />
            <div class="y-f rightBox">
              <div
                v-for="(item, i) in overviewData.ageQtyList"
                :key="i"
                class="ageBgc x-f"
              >
                <div class="ageData">
                  {{ getQtyRangeTextAge(item.beginAge, item.endAge) }}
                </div>
                <div class="ageRecharge">
                  {{
                    selectedKeyType === "1"
                      ? item.ageQty + "人"
                      : item.consumeMoney + "元"
                  }}
                </div>
              </div>
            </div>
            <el-dialog
              title="会员年龄段划分 (注:最多分10段)"
              :visible.sync="dialogTableVisible"
              width="820px"
              v-dialogDrag
            >
              <el-table
                :data="gridData"
                border
                max-height="400px"
                height="400px"
                width="auto"
              >
                <el-table-column
                  property="date"
                  label="操作"
                  width="120px"
                  align="center"
                >
                  <template v-slot="scope">
                    <i
                      @click="rowAvailable('push')"
                      class="el-icon-circle-plus operatePush"
                      :class="{ disabled: gridData.length >= 9 }"
                    ></i>
                    <i
                      @click="clearLastRowDataAge(scope.$index)"
                      class="el-icon-remove operateDel"
                      :class="{ disabled: gridData.length === 1 }"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column property="name" label="起始年龄" align="center">
                  <template slot-scope="scope">
                    <el-input
                      type="number"
                      v-model="scope.row.beginAge"
                      width="80px"
                      class="inputWidth"
                      @input="validateAgeInput(scope.row, 'beginAge')"
                    >
                    </el-input>
                    <span class="marL10">岁</span></template
                  ></el-table-column
                >
                <el-table-column label="至" width="60px" align="center"></el-table-column>
                <el-table-column property="address" label="结束年龄" align="center">
                  <template slot-scope="scope">
                    <el-input
                      type="number"
                      v-model="scope.row.endAge"
                      width="80px"
                      class="inputWidth"
                      @input="validateAgeInput(scope.row, 'endAge')"
                    >
                    </el-input>
                    <span class="marL10">岁</span></template
                  >
                </el-table-column>
              </el-table>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateQtyRangeAndFetchDataAge"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </div>
        </div>
      </el-card>

      <!-- 会员消费次数 -->
      <el-card class="marT10 styleRightQty">
        <div>
          <div class="x-bc">
            <div class="distributionTitleText">
              <span>会员消费次数</span>
            </div>
            <div
              id="myDiv"
              style="
                color: #409eff;
                cursor: pointer;
                margin-bottom: 15px;
                font-size: 15px;
              "
              @click="dialogTableVisibleQty = true"
            >
              设置
            </div>
          </div>

          <div class="x-b">
            <div id="chart-style-right" style="width: 500px; height: 400px" />
            <div class="y-f rightBox">
              <div
                v-for="(item, i) in overviewData.consumptionRangeResps"
                :key="i"
                class="ageBgc x-f"
              >
                <div class="ageData">
                  {{ getQtyRangeText(item.beginQty, item.endQty) }}
                </div>
                <div class="ageRecharge">{{ item.peopleQty }}人</div>
              </div>
            </div>
          </div>
          <el-dialog
            title="会员消费次数划分 (注:最多分10段)"
            :visible.sync="dialogTableVisibleQty"
            width="820px"
          >
            <el-table
              :data="gridDataQty"
              border
              max-height="400px"
              height="400px"
              width="auto"
            >
              <el-table-column property="date" label="操作" width="120px" align="center">
                <template v-slot="scope">
                  <i
                    @click="rowAvailableQty('push')"
                    class="el-icon-circle-plus operatePush"
                    :class="{ disabled: gridDataQty.length >= 9 }"
                  ></i>
                  <i
                    @click="clearLastRowData(scope.$index)"
                    class="el-icon-remove operateDel"
                    :class="{ disabled: gridDataQty.length === 1 }"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column property="name" label="起始次数" align="center">
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    v-model="scope.row.beginQty"
                    width="80px"
                    class="inputWidth"
                    @input="validateAgeInputQty(scope.row, 'beginQty')"
                  >
                  </el-input>
                  <span class="marL10">次</span></template
                ></el-table-column
              >
              <el-table-column label="至" width="60px" align="center"></el-table-column>
              <el-table-column property="address" label="结束次数" align="center">
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    v-model="scope.row.endQty"
                    width="80px"
                    class="inputWidth"
                    @input="validateAgeInputQty(scope.row, 'endQty')"
                  >
                  </el-input>
                  <span class="marL10">次</span></template
                >
              </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogTableVisibleQty = false">取 消</el-button>
              <el-button type="primary" @click="updateQtyRangeAndFetchData"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </el-card>
    </div>
    <!-- 会员增长分析 -->
    <el-card class="marT10">
      <div>
        <div class="x-bc">
          <div class="distributionTitleText">
            <span>会员增长分析</span>
          </div>
          <div>
            <div>
              <el-date-picker
                @change="fetchOverviewData('vipIncrease')"
                v-model="pickerTimeAdd"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
              >
              </el-date-picker>
              <timeRange
                format="daterange"
                default-value="week"
                v-model="pickerTimeAdd"
                size="mini"
                :data="['week', 'month', 'year']"
                @change="handleTimeRangeChangeAdd"
              />
            </div>
          </div>
        </div>
        <div id="chart-containerAdd" style="width: 100%; height: 420px"></div>
      </div>
    </el-card>
  </div>
</template>
<script>
import seniorSearch from "@/views/components/seniorSearch"; // 高级搜索
import bottomPage from "@/views/components/bottomPage"; // 底部分页
import { getOverviewDataList } from "@/api/vip/report/vipView";
import timeRange from "@/components/tablePage/time-range/index.vue";
import InputNumberRange from "@/components/tablePage/input-number/index.vue";
import { getTimes } from "@/utils/index.js";
export default {
  name: "vipViewComponent",
  components: {
    seniorSearch,
    bottomPage,
    timeRange,
    InputNumberRange,
  },
  data() {
    return {
      pieChartDataAgeByQty: [], // 人数占比饼图数据
      pieChartDataAgeByMoney: [], // 金额占比饼图数据
      pieChartData: [], // 消费次数初始化为空数组
      pieChartDataAge: [],
      customRangeSet: false, // 消费次数追踪用户是否设置了自定义范围
      hasUserSetConsumptionRange: false, //消费次数是否展示默认范围那组
      gridData: JSON.parse(localStorage.getItem("gridData")) || [{}], // 初始的表格数据，至少有一行数据
      gridDataQty: JSON.parse(localStorage.getItem("gridDataQty")) || [{}],
      selectedData: [], // 性别 存储当前选中的数据值
      chartSex: null, // 存储 ECharts 实例
      myChartAdd: null,
      pickerTime: [],
      pickerTimeAdd: [],
      ageQtyList: [],
      manData: 0,
      maleData: 0,
      unknowData: 0,
      activeIndex: "1",
      activeIndexAge: "1",
      selectedKeyType: "1", // 默认选中的数据类型（人数或金额）
      dateRange: [], // 会员消费趋势 保存选定的日期范围
      xAxisData: [], // 消费趋势初始化 x 轴数据
      seriesData: [], // 初始化系列数据
      XData: [],
      YData: [],
      selectedTrendFilter: "thisWeek", //会员消费趋势 默认选择的过滤器
      selectedTimeFilter: "thisWeek",
      consumTrendList: [], //消费趋势
      consumPeopleQtyList: [], // 后端返回的会员消费次数数据
      searchTypeList: [],
      ratio: null,
      overviewData: {
        allConsumeMoney: "", //累计消费金额
        vipAddRate: "", //会员新增数量同比增长(%)
        vipCustomerUnitPriceRate: "", //会员客单价同比增长(%)
        vipFillMoneyRate: "", // 会员充值金额同比增长(%)
        vipPayCountRate: "", //会员付款笔数同比增长(%)
        vipScoreRate: "", //会员积分同比增长(%)
        vipAdd: "",
        vipAddRate: "",
        vipAddRingRate: "",
        vipCustomerUnitPrice: "",
        vipCustomerUnitPriceRate: "",
        vipCustomerUnitPriceRingRate: "",
        vipFillMoney: "",
        vipFillMoneyRate: "",
        vipFillMoneyRingRate: "",
      },
      statisticsData: [],
      dialogTableVisible: false, //年龄
      dialogTableVisibleQty: false, //消费次数
    };
  },
  computed: {
    consumptionRangeQuery() {
      let arr = this.gridDataQty?.filter?.((x) => x.beginQty && x.endQty) || [];
      if (!arr?.length) {
        arr = [
          {
            beginQty: 1,
            endQty: 0,
          },
          {
            beginQty: 2,
            endQty: 4,
          },
          {
            beginQty: 5,
            endQty: 9,
          },
          {
            beginQty: 10,
            endQty: 14,
          },
        ];
      }
      return arr;
    },
    ageDistributionList() {
      let arr = this.gridData?.filter?.((x) => x.beginAge && x.endAge) || [];
      if (!arr?.length) {
        arr = [
          {
            beginAge: 10,
            endAge: 20,
          },
          {
            beginAge: 20,
            endAge: 30,
          },
          {
            beginAge: 30,
            endAge: 40,
          },
          {
            beginAge: 40,
            endAge: 50,
          },
        ];
      }
      return arr;
    },
  },
  created() {
    console.log(this.pickerTime, "时间");
  },
  methods: {
    // 验证年龄输入，确保起始年龄不大于结束年龄
    validateAgeInput(row, field) {
      const input = field === "beginAge" ? row.beginAge : row.endAge;
      const regex = /^\d+$/; // 正则表达式匹配非负整数

      if (!regex.test(input) || input < 0) {
        // 如果输入不是非负整数或小于0，则置为0
        if (field === "beginAge") {
          row.beginAge = "";
        } else {
          row.endAge = "";
        }
      }
    },
    validateAgeInputQty(row, field) {
      const input = field === "beginQty" ? row.beginQty : row.endQty;
      const regex = /^\d+$/; // 正则表达式匹配非负整数

      if (!regex.test(input) || input < 0) {
        // 如果输入不是非负整数或小于0，则置为0
        if (field === "beginQty") {
          row.beginQty = "";
        } else {
          row.endQty = "";
        }
      }
    },
    getQtyRangeText(beginQty, endQty) {
      //展示后端数据----会员消费次数
      if (beginQty === 1 && endQty === 0) {
        return "1次"; // 这里可能需要根据实际情况进行逻辑判断
      } else if (beginQty === 2 && endQty === 4) {
        return "2~4次";
      } else if (beginQty === 5 && endQty === 9) {
        return "5~9次";
      } else if (beginQty === 10 && endQty === 14) {
        return "10~14次";
      } else if (beginQty === -1 && endQty === -1) {
        return `其他`;
      } else if (beginQty === endQty) {
        return `${beginQty || endQty}次`; // 如果起始和结束相同，展示具体次数
      } else {
        return `${beginQty}~${endQty}次`; // 其他范围展示
      }
    },
    getQtyRangeTextAge(beginAge, endAge) {
      //展示后端数据----会员消费次数
      if (beginAge === -1 && endAge === -1) {
        return `其他`;
      } else if (beginAge === endAge) {
        return `${beginAge || endAge}岁`; // 如果起始和结束相同，展示具体次数
      } else {
        return `${beginAge}~${endAge}岁`; // 其他范围展示
      }
    },
    async handleTimeRangeChange(res) {
      //消费趋势分析时间切换
      await this.fetchOverviewData("vipConsumer"); // 发送请求
      this.initChart();
    },
    async handleTimeRangeChangeAdd() {
      await this.fetchOverviewData("vipIncrease"); // 发送请求
      this.initChartAdd();
    },
    getArrowIcon(value) {
      return value > 0 ? "up-arrows" : "down-arrows";
    },

    //性别分析
    handleSelect(key, keyPath) {
      if (key === "1") {
        this.manData = this.overviewData.maleQty;
        this.maleData = this.overviewData.femaleQty;
        this.unknowData = this.overviewData.unknownQty;
        this.selectedData = [
          this.overviewData.maleQtyRate,
          this.overviewData.femaleQtyRate,
          this.overviewData.unknownQtyRate,
        ];
      } else if (key === "2") {
        this.manData = this.overviewData.maleConsumMoney;
        this.maleData = this.overviewData.femaleConsumMoney;
        this.unknowData = this.overviewData.unknownConsumMoney;
        this.selectedData = [
          this.overviewData.maleConsumMoneyRate,
          this.overviewData.femaleConsumMoneyRate,
          this.overviewData.unknownConsumMoneyRate,
        ];
      }
      this.activeIndex = key;
      this.updateChart(); // 更新图表数据
    },
    //性别分析
    updateChart() {
      this.chartSex.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "bottom",
          selectedMode: true,
          icon: "circle", //圆形
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "50%"],
            center: ["50%", "50%"],
            startAngle: 180,
            label: {
              show: true,
              formatter(param) {
                return param.name + " (" + param.percent + "%)"; // 标签的显示格式
              },
              color: "#333",
            },
            data: this.generateChartData(),
          },
        ],
      });
      // 监听窗口大小变化，实现图表的自适应
      window.addEventListener("resize", () => {
        this.chartSex.resize(); // 使用 this.chartSex.resize()
      });
    },

    //性别分析
    generateChartData() {
      const data = [
        {
          value: this.selectedData[0],
          name: "男性占比",
          itemStyle: { color: "#4E95FF" },
        },
        {
          value: this.selectedData[1],
          name: "女性占比",
          itemStyle: { color: "#FA8219" },
        },
        {
          value: this.selectedData[2],
          name: "未知占比",
          itemStyle: { color: "#9477F9" },
        },
      ];
      return data;
    },
    //年龄分布--人数金额选项卡
    handleSelectAge(key, keyPath) {
      this.activeIndexAge = key; // 更新选中项
      this.selectedKeyType = key === "1" ? "1" : "2"; // 更新选中的数据类型
      if (this.selectedKeyType === "1") {
        // 根据选中的数据类型来更新圆环图数据
        this.updateChartAge(this.pieChartDataAgeByQty);
      } else if (this.selectedKeyType === "2") {
        this.updateChartAge(this.pieChartDataAgeByMoney);
      }
    },
    updateChartAge(e) {
      //年龄分布
      const chartAgeLeft = document.getElementById("chart-age-right");
      const myChartAgeLeft = this.$echarts.init(chartAgeLeft);
      myChartAgeLeft.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "bottom",
          selectedMode: true,
          icon: "circle", //圆形
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "50%"],
            center: ["50%", "50%"],
            startAngle: 180,
            label: {
              show: true,
              formatter(param) {
                return param.name + "(" + param.percent + "%)";
              },
              color: "#333",
            },
            data: e,
          },
        ],
      });
      // 监听窗口大小变化，实现图表的自适应
      window.addEventListener("resize", () => {
        myChartAgeLeft.resize();
      });
    },
    async updateQtyRangeAndFetchData() {
      /* //会员消费次数
      //消费次数设置点确定提交次数范围的请求函数
      // 设置标志以指示用户已设置消费范围
      this.hasUserSetConsumptionRange = true;
      // 获取用户设置的次数范围
      try {
        const response = await getOverviewDataList({
          ageDistributionList: this.ageDistributionList,
          consumptionRangeQuery: this.consumptionRangeQuery,
          useAnalysisBeginDate: this.pickerTime[0], // 开始日期
          useAnalysisEndBillDate: this.pickerTime[1], // 结束日期
          useAnalysisFilterTime: "thisYear", //消费趋势分析时间过滤参数

          vipAnalysisFilterTime: this.selectedTimeFilter, //会员同比分析时间过滤参数

          vipIncreaseBeginDate: "thisWeek", //会员增长分析
          vipIncreaseEndBillDate: this.pickerTimeAdd[0],
          vipIncreaseFilterTime: this.pickerTimeAdd[1],
        });

        // 处理response.data中的数字数据
        this.formatNumbersToFixed(response.data);
        this.overviewData = response.data;
        const consumptionRangeResps = this.overviewData.consumptionRangeResps;

        // 生成饼图数据，并为每个数据项设置不同颜色
        this.pieChartData = consumptionRangeResps.map((item, index) => {
          const colors = [
            "#FFC0CB",
            "#50D873",
            "#FCCB1F",
            "#FF5733",
            "#4E95FF",
            "#FFA500",
            "#800080",
            "#008080",
            "#FF1493",
            "#00FF00",
          ];
          let name;
          if (item.beginQty === 1 && item.endQty === 0) {
            name = "1次";
          } else if (item.beginQty === -1 && item.endQty === -1) {
            name = "其他";
          } else {
            name = `${item.beginQty}次 - ${item.endQty}次`;
          }

          return {
            value: item.useProportion,
            name: name,
            itemStyle: { color: colors[index % colors.length] },
          };
        });
        this.initChartStyleRight(); //消费次数
      } catch (error) {
        console.error(error);
      }
      localStorage.setItem("gridDataQty", JSON.stringify(this.gridDataQty)); // 将数据保存到本地存储 */
      this.fetchOverviewData("vipConsumerCount");

      // 关闭对话框
      this.dialogTableVisibleQty = false;
    },
    async updateQtyRangeAndFetchDataAge() {
      /* try {
        const response = await getOverviewDataList({
          ageDistributionList: this.ageDistributionList,
          consumptionRangeQuery: this.consumptionRangeQuery,
          useAnalysisBeginDate: this.pickerTime[0], // 开始日期
          useAnalysisEndBillDate: this.pickerTime[1], // 结束日期
          useAnalysisFilterTime: "thisYear", //消费趋势分析时间过滤参数

          vipAnalysisFilterTime: this.selectedTimeFilter, //会员同比分析时间过滤参数

          vipIncreaseBeginDate: this.pickerTimeAdd[0], //会员增长分析
          vipIncreaseEndBillDate: this.pickerTimeAdd[1],
          vipIncreaseFilterTime: "thisYear",
        });

        // 处理response.data中的数字数据
        this.formatNumbersToFixed(response.data);
        this.overviewData = response.data;
        const consumptionRangeResps = this.overviewData.consumptionRangeResps;

        // 生成人数占比饼图数据
        this.pieChartDataAgeByQty = this.overviewData.ageQtyList.map(
          (item, index) => {
            const colors = [
              "#FFC0CB",
              "#50D873",
              "#FCCB1F",
              "#FF5733",
              "#4E95FF",
              "#FFA500",
              "#800080",
              "#008080",
              "#FF1493",
              "#00FF00",
            ];
            let name;
            if (item.beginAge === -1 && item.endAge === -1) {
              name = "其他";
            } else {
              name = `${item.beginAge}岁 - ${item.endAge}岁`;
            }

            return {
              value: item.ageQtyRate,
              name: name,
              itemStyle: { color: colors[index % colors.length] },
            };
          }
        );
        // 生金额占比饼图数据
        this.pieChartDataAgeByMoney = this.overviewData.ageQtyList.map(
          (item, index) => {
            const colors = [
              "#FFC0CB",
              "#50D873",
              "#FCCB1F",
              "#FF5733",
              "#4E95FF",
              "#FFA500",
              "#800080",
              "#008080",
              "#FF1493",
              "#00FF00",
            ];
            let name;
            if (item.beginAge === -1 && item.endAge === -1) {
              name = "其他";
            } else {
              name = `${item.beginAge}岁 - ${item.endAge}岁`;
            }

            return {
              value: item.consumeMoneyRate,
              name: name,
              itemStyle: { color: colors[index % colors.length] },
            };
          }
        );
        this.updateChartAge(this.pieChartDataAgeByQty); //年龄分布
      } catch (error) {
        console.error(error);
      }
      localStorage.setItem("gridData", JSON.stringify(this.gridData)); // 将数据保存到本地存储 */
      this.fetchOverviewData("vipAgeConsumerMoney");
      // 关闭对话框
      this.dialogTableVisible = false;
    },
    //----------请求函数-----------
    async fetchOverviewData(queryType) {
      try {
        let thisWeek = getTimes({
          type: "week",
          format: "daterange",
        });
        const response = await getOverviewDataList({
          queryType,
          ageDistributionList: this.ageDistributionList,
          consumptionRangeQuery: this.consumptionRangeQuery,
          useAnalysisBeginDate: this.pickerTime[0], // 开始日期
          useAnalysisEndBillDate: this.pickerTime[1], // 结束日期
          // useAnalysisFilterTime: "thisYear", //消费趋势分析时间过滤参数
          vipAnalysisFilterTime: this.selectedTimeFilter, //会员同比分析时间过滤参数
          vipIncreaseBeginDate: this.pickerTimeAdd[0], //会员增长分析
          vipIncreaseEndBillDate: this.pickerTimeAdd[1],
          // vipIncreaseFilterTime: "thisYear",
        });
        // 处理response.data中的数字数据
        this.formatNumbersToFixed(response.data);

        switch (queryType) {
          case "vipYOY": //会员同比分析
            {
              this.overviewData.vipFillMoney = response.data.vipFillMoney;
              this.overviewData.vipFillMoneyRate = response.data.vipFillMoneyRate;
              this.overviewData.vipFillMoneyRingRate = response.data.vipFillMoneyRingRate;
              this.overviewData.vipAdd = response.data.vipAdd;
              this.overviewData.vipAddRate = response.data.vipAddRate;
              this.overviewData.vipAddRingRate = response.data.vipAddRingRate;
              this.overviewData.vipCustomerUnitPrice = response.data.vipCustomerUnitPrice;
              this.overviewData.vipCustomerUnitPriceRate =
                response.data.vipCustomerUnitPriceRate;
              this.overviewData.vipCustomerUnitPriceRingRate =
                response.data.vipCustomerUnitPriceRingRate;
              this.overviewData.vipPayCount = response.data.vipPayCount;
              this.overviewData.vipPayCountRate = response.data.vipPayCountRate;
              this.overviewData.vipPayCountRingRate = response.data.vipPayCountRingRate;
              this.overviewData.vipPayMoney = response.data.vipPayMoney;
              this.overviewData.vipPayMoneyRate = response.data.vipPayMoneyRate;
              this.overviewData.vipPayMoneyRingRate = response.data.vipPayMoneyRingRate;
              this.overviewData.vipScore = response.data.vipScore;
              this.overviewData.vipScoreRate = response.data.vipScoreRate;
              this.overviewData.vipScoreRingRate = response.data.vipScoreRingRate;
              this.handleVipAnalyse();
            }
            break;
          case "vipConsumer": //消费趋势分析
            {
              this.overviewData.consumTrendList = response.data.consumTrendList;
              this.handleConsumerTrend();
            }
            break;
          case "vipAgeConsumerMoney": //年龄分布与消费金额
            {
              this.overviewData.ageQtyList = response.data.ageQtyList;
              this.handleVipType();
            }
            break;
          case "vipConsumerCount": //会员消费次数
            {
              this.overviewData.consumptionRangeResps =
                response.data.consumptionRangeResps;
              this.handleVipConsumerTime();
            }
            break;
          case "vipIncrease": //会员增长分析
            {
              this.overviewData.vipIncreaseList = response.data.vipIncreaseList;
              this.handleVipIncrease();
            }
            break;
          case "": //默认全部
            this.overviewData = response.data;
            this.handleVipAnalyse();
            this.handleConsumerTrend();
            this.handleVipType();
            this.handleVipConsumerTime();
            this.handleVipIncrease();
            break;
          default:
            break;
        }

        /* this.overviewData.consumTrendList.forEach((item) => {
          //消费趋势
          this.xAxisData.push(item.consumeDate);
          this.seriesData.push(item.consumeMoney);
        });
        // 消费次数 生成饼图数据，并设置不同的颜色
        // 将 consumptionRangeResps 设置为 overviewData 中的数据
        const consumptionRangeResps = this.overviewData.consumptionRangeResps;

        // 生成饼图数据，并为每个数据项设置不同颜色
        this.pieChartData = consumptionRangeResps.map((item, index) => {
          const colors = [
            "#FFC0CB",
            "#50D873",
            "#FCCB1F",
            "#FF5733",
            "#4E95FF",
          ];

          let name;
          if (item.beginQty === 1 && item.endQty === 0) {
            name = "1次占比";
          } else if (item.beginQty === -1 && item.endQty === -1) {
            name = "其他占比";
          } else {
            name = `${item.beginQty}次 - ${item.endQty}次占比`;
          }

          return {
            value: item.useProportion,
            name: name,
            itemStyle: { color: colors[index % colors.length] },
          };
        });
        // 生成人数占比饼图数据
        this.pieChartDataAgeByQty = this.overviewData.ageQtyList.map(
          (item, index) => {
            const colors = [
              "#FFC0CB",
              "#50D873",
              "#FCCB1F",
              "#FF5733",
              "#4E95FF",
              "#FFA500",
              "#800080",
              "#008080",
              "#FF1493",
              "#00FF00",
            ];
            let name;
            if (item.beginAge === -1 && item.endAge === -1) {
              name = "其他占比";
            } else {
              name = `${item.beginAge}岁 - ${item.endAge}岁占比`;
            }

            return {
              value: item.ageQtyRate,
              name: name,
              itemStyle: { color: colors[index % colors.length] },
            };
          }
        );
        // 生金额占比饼图数据
        this.pieChartDataAgeByMoney = this.overviewData.ageQtyList.map(
          (item, index) => {
            const colors = [
              "#FFC0CB",
              "#50D873",
              "#FCCB1F",
              "#FF5733",
              "#4E95FF",
              "#FFA500",
              "#800080",
              "#008080",
              "#FF1493",
              "#00FF00",
            ];
            let name;
            if (item.beginAge === -1 && item.endAge === -1) {
              name = "其他占比";
            } else {
              name = `${item.beginAge}岁 - ${item.endAge}岁占比`;
            }

            return {
              value: item.consumeMoneyRate,
              name: name,
              itemStyle: { color: colors[index % colors.length] },
            };
          }
        );
        this.overviewData.vipIncreaseList.forEach((item) => {
          //会员增长
          this.XData.push(item.vipDate);
          this.YData.push(item.vipQty);
        });
        //会员同比分析
        this.statisticsData = [
          {
            label: "会员充值金额(元)",
            value: this.overviewData.vipFillMoney || 0,
            yoyGrowth: this.overviewData.vipFillMoneyRate || 0,
            momGrowth: this.overviewData.vipFillMoneyRingRate || 0,
          },
          {
            label: "会员新增数量(人)",
            value: this.overviewData.vipAdd || 0,
            yoyGrowth: this.overviewData.vipAddRate || 0,
            momGrowth: this.overviewData.vipAddRingRate || 0,
          },
          {
            label: "会员客单价(元)",
            value: this.overviewData.vipCustomerUnitPrice || 0,
            yoyGrowth: this.overviewData.vipCustomerUnitPriceRate || 0,
            momGrowth: this.overviewData.vipCustomerUnitPriceRingRate || 0,
          },
          {
            label: "会员付款笔数(笔)",
            value: this.overviewData.vipPayCount || 0,
            yoyGrowth: this.overviewData.vipPayCountRate || 0,
            momGrowth: this.overviewData.vipPayCountRingRate || 0,
          },
          {
            label: "会员积分(分)",
            value: this.overviewData.vipScore || 0,
            yoyGrowth: this.overviewData.vipScoreRate || 0,
            momGrowth: this.overviewData.vipScoreRingRate || 0,
          },
          // ... 添加其他数据项 ...
        ]; */
        console.log("this.overviewData", this.overviewData);
      } catch (error) {
        console.error(error);
      }
    },
    //会员同比分析处理
    handleVipAnalyse() {
      this.statisticsData = [
        {
          label: "会员充值金额(元)",
          value: this.overviewData.vipFillMoney || 0,
          yoyGrowth: this.overviewData.vipFillMoneyRate || 0,
          momGrowth: this.overviewData.vipFillMoneyRingRate || 0,
        },
        {
          label: "会员新增数量(人)",
          value: this.overviewData.vipAdd || 0,
          yoyGrowth: this.overviewData.vipAddRate || 0,
          momGrowth: this.overviewData.vipAddRingRate || 0,
        },
        {
          label: "会员客单价(元)",
          value: this.overviewData.vipCustomerUnitPrice || 0,
          yoyGrowth: this.overviewData.vipCustomerUnitPriceRate || 0,
          momGrowth: this.overviewData.vipCustomerUnitPriceRingRate || 0,
        },
        {
          label: "会员客单数(个)",
          value: this.overviewData.vipSingular || 0,
          yoyGrowth: this.overviewData.vipSingularRate || 0,
          momGrowth: this.overviewData.vipSingularRingRate || 0,
        },
        {
          label: "会员消费金额(元)",
          value: this.overviewData.vipConsumeMoney || 0,
          yoyGrowth: this.overviewData.vipConsumeMoneyRate || 0,
          momGrowth: this.overviewData.vipConsumeMoneyRingRate || 0,
        },
        {
          label: "会员付款金额(元)",
          value: this.overviewData.vipPayMoney || 0,
          yoyGrowth: this.overviewData.vipPayMoneyRate || 0,
          momGrowth: this.overviewData.vipPayMoneyRingRate || 0,
        },
        {
          label: "会员积分(分)",
          value: this.overviewData.vipScore || 0,
          yoyGrowth: this.overviewData.vipScoreRate || 0,
          momGrowth: this.overviewData.vipScoreRingRate || 0,
        },
        // ... 添加其他数据项 ...
      ];
    },
    //消费趋势分析处理
    handleConsumerTrend() {
      this.overviewData.consumTrendList.forEach((item) => {
        this.xAxisData.push(item.consumeDate);
        this.seriesData.push(item.consumeMoney);
      });
    },
    //会员类型分布处理
    handleVipType() {
      // 生成人数占比饼图数据
      this.pieChartDataAgeByQty = this.overviewData.ageQtyList.map((item, index) => {
        const colors = [
          "#FFC0CB",
          "#50D873",
          "#FCCB1F",
          "#FF5733",
          "#4E95FF",
          "#FFA500",
          "#800080",
          "#008080",
          "#FF1493",
          "#00FF00",
        ];
        let name;
        if (item.beginAge === -1 && item.endAge === -1) {
          name = "其他占比";
        } else {
          name = `${item.beginAge}岁 - ${item.endAge}岁占比`;
        }

        return {
          value: item.ageQtyRate,
          name: name,
          itemStyle: { color: colors[index % colors.length] },
        };
      });
      // 生金额占比饼图数据
      this.pieChartDataAgeByMoney = this.overviewData.ageQtyList.map((item, index) => {
        const colors = [
          "#FFC0CB",
          "#50D873",
          "#FCCB1F",
          "#FF5733",
          "#4E95FF",
          "#FFA500",
          "#800080",
          "#008080",
          "#FF1493",
          "#00FF00",
        ];
        let name;
        if (item.beginAge === -1 && item.endAge === -1) {
          name = "其他占比";
        } else {
          name = `${item.beginAge}岁 - ${item.endAge}岁占比`;
        }

        return {
          value: item.consumeMoneyRate,
          name: name,
          itemStyle: { color: colors[index % colors.length] },
        };
      });
      this.updateChartAge(this.pieChartDataAgeByQty); //年龄分布
      localStorage.setItem("gridData", JSON.stringify(this.gridData)); // 将数据保存到本地存储
    },
    //会员消费次数处理
    handleVipConsumerTime() {
      //会员消费次数
      //消费次数设置点确定提交次数范围的请求函数
      // 设置标志以指示用户已设置消费范围
      this.hasUserSetConsumptionRange = true;
      const consumptionRangeResps = this.overviewData.consumptionRangeResps;

      // 生成饼图数据，并为每个数据项设置不同颜色
      this.pieChartData = consumptionRangeResps.map((item, index) => {
        const colors = [
          "#FFC0CB",
          "#50D873",
          "#FCCB1F",
          "#FF5733",
          "#4E95FF",
          "#FFA500",
          "#800080",
          "#008080",
          "#FF1493",
          "#00FF00",
        ];
        let name;
        if (item.beginQty === 1 && item.endQty === 0) {
          name = "1次占比";
        } else if (item.beginQty === -1 && item.endQty === -1) {
          name = "其他占比";
        } else {
          name = `${item.beginQty}次 - ${item.endQty}次占比`;
        }

        return {
          value: item.useProportion,
          name: name,
          itemStyle: { color: colors[index % colors.length] },
        };
      });
      this.initChartStyleRight(); //消费次数
      localStorage.setItem("gridDataQty", JSON.stringify(this.gridDataQty)); // 将数据保存到本地存储
    },
    //会员增长分析处理
    handleVipIncrease() {
      this.overviewData.vipIncreaseList.forEach((item) => {
        //会员增长
        this.XData.push(item.vipDate);
        this.YData.push(item.vipQty);
      });
    },
    async formatNumbersToFixed(obj) {
      if (typeof obj === "object" && obj !== null) {
        for (const key in obj) {
          if (typeof obj[key] === "number") {
            obj[key] = parseFloat(obj[key].toFixed(2)); // 注意转换为浮点数
          } else if (typeof obj[key] === "object") {
            this.formatNumbersToFixed(obj[key]); // 使用this来递归调用方法
          }
        }
      }
    },
    initChartStyleRight() {
      //消费次数
      const chartStyleRight = document.getElementById("chart-style-right"); //获取右侧消费次数
      const myChartStyleRight = this.$echarts.init(chartStyleRight);
      myChartStyleRight.setOption({
        //获取右侧消费次数
        tooltip: {
          trigger: "item", // 提示框触发类型
        },
        legend: {
          top: "bottom", // 图例组件的垂直位置
          selectedMode: true, // 关闭图例的点击事件
          icon: "circle",
        },
        series: [
          {
            // name: "Access From", // 系列名称
            type: "pie", // 图表类型为饼图
            radius: ["40%", "50%"], // 饼图的内外半径
            center: ["50%", "50%"], // 饼图的中心位置
            startAngle: 180, // 饼图的起始角度
            label: {
              show: true, // 是否显示标签
              formatter(param) {
                return param.name + " (" + param.percent + "%)"; // 标签的显示格式
              },
              color: "#333",
            },
            data: this.pieChartData,
          },
        ],
      });
      // 监听窗口大小变化，实现图表的自适应
      window.addEventListener("resize", () => {
        myChartStyleRight.resize();
      });
    },
    //销售趋势
    initChart() {
      console.log("this.xAxisData", this.xAxisData);
      console.log("this.seriesData", this.seriesData);
      //消费趋势
      if (this.myChart) {
        this.myChart.dispose(); // 销毁之前的实例
      }
      const chartContainer = document.getElementById("chart-container");
      this.myChart = this.$echarts.init(chartContainer);
      //消费趋势分析
      this.myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            crossStyle: {
              color: "#73d13d",
            },
          },
          formatter: function (params) {
            return (
              params[0].name + "<br>" + params[0].marker + `消费:${params[0].data}(元)`
            );
          },
        },
        grid: {
          left: "1%", // 控制图表左边空白
          right: "1%", // 控制图表右边空白
          bottom: "5%", // 控制图表底部空白
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.xAxisData,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#999999",
              },
            },

            boundaryGap: false, // 设置为 false 减小左右留白
            axisLabel: {
              fontSize: 14, // 设置字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            data: this.seriesData,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14, // 设置字体大小
            },
          },
        ],
        series: [
          {
            type: "line",
            data: this.seriesData,
            symbol: "circle",
            symbolSize: 8,
            itemStyle: {
              color: "#40B5FF",
            },
            lineStyle: {
              color: "#40B5FF",
            },
          },
        ],
        dataZoom: [
          {
            type: "slider", // 使用滑动条型数据区域缩放组件
            xAxisIndex: 0, // 设置要缩放的 x 轴索引
            start: 0, // 设置默认视口起始位置
            end: 100, // 设置默认视口结束位置（可以根据需求调整）
            bottom: -5, // 调整滚动条的底部位置，以远离 x 轴
            height: 15,
          },
          {
            type: "inside", // 内置型数据区域缩放组件
            xAxisIndex: 0, // 设置要缩放的 x 轴索引
            start: 0, // 设置默认视口起始位置
            end: 20, // 设置默认视口结束位置（可以根据需求调整）
          },
        ],
      });
      //图表大小变动重新渲染，动态自适应
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
      // this.myChart.setOption(option);
      this.xAxisData = []; //清空数据
      this.seriesData = [];
    },
    //会员增长
    initChartAdd() {
      //会员增长
      if (this.myChartAdd) {
        this.myChartAdd.dispose(); // 销毁之前的实例
      }
      const chartContainerAdd = document.getElementById("chart-containerAdd");
      this.myChartAdd = this.$echarts.init(chartContainerAdd);
      //会员增长
      this.myChartAdd.setOption({
        tooltip: {
          trigger: "axis",
          // axisPointer: {
          //   type: "shadow", // 修改为柱状图的提示框样式
          // },
          axisPointer: {
            type: "line",
            lineStyle: {
              type: "dash",
            },
          },
          formatter: function (params) {
            return (
              params[0].name + "<br>" + params[0].marker + `人数:${params[0].data}(人)`
            );
          },
        },
        grid: {
          left: "1%",
          right: "1%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.XData,
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#999999",
              },
            },
            boundaryGap: true, // 在柱状图中通常需要设置为 true
            axisLabel: {
              fontSize: 14, // 设置字体大小
            },
          },
        ],
        yAxis: [
          {
            type: "value",

            axisLine: {
              show: false,
              lineStyle: {
                color: "#999999",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            axisLabel: {
              fontSize: 14, // 设置字体大小
            },
          },
        ],
        series: [
          {
            type: "bar", // 将折线图改为柱状图
            data: this.YData, // 将柱状图的数据设置为 YData
            barWidth: "5%",
            // data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: "#4E95FF",
            },
          },
        ],
        dataZoom: [
          {
            type: "slider", // 使用滑动条型数据区域缩放组件
            xAxisIndex: 0, // 设置要缩放的 x 轴索引
            start: 0, // 设置默认视口起始位置
            end: 100, // 设置默认视口结束位置（可以根据需求调整）
            bottom: -5, // 调整滚动条的底部位置，以远离 x 轴
            height: 15,
          },
          {
            type: "inside", // 内置型数据区域缩放组件
            xAxisIndex: 0, // 设置要缩放的 x 轴索引
            start: 0, // 设置默认视口起始位置
            end: 20, // 设置默认视口结束位置（可以根据需求调整）
          },
        ],
      });
      this.XData = []; //清空数据
      this.YData = [];

      //图表大小变动重新渲染，动态自适应
      window.addEventListener("resize", () => {
        this.myChartAdd.resize();
      });
    },
    // 表格当中增加/减少一行方法
    rowAvailable(action, index) {
      if (action === "push" && this.gridData.length < 9) {
        this.gridData.push({
          /* 新行的初始数据 */
        });
      } else if (action === "del" && this.gridData.length > 1) {
        this.gridData.splice(index, 1);
      }
    },
    // 表格当中增加/减少一行方法
    clearLastRowData(index) {
      if (this.gridDataQty.length === 1) {
        const lastRow = this.gridDataQty[0];
        for (const key in lastRow) {
          if (Object.hasOwnProperty.call(lastRow, key)) {
            lastRow[key] = null; // 清空数据
          }
        }
        localStorage.setItem("gridDataQty", JSON.stringify(this.gridDataQty)); // 更新本地存储
      } else if (this.gridDataQty.length > 1) {
        // 删除最后一行
        this.gridDataQty.splice(index, 1);
        localStorage.setItem("gridDataQty", JSON.stringify(this.gridDataQty)); // 删除后更新本地存储
      }
    },
    rowAvailable(action, index) {
      if (action === "push" && this.gridData.length < 9) {
        this.gridData.push({
          /* 新行的初始数据 */
        });
      } else if (action === "del" && this.gridData.length > 1) {
        this.gridData.splice(index, 1);
        localStorage.setItem("gridData", JSON.stringify(this.gridData)); // 删除后更新本地存储
      }
    },
    // 表格当中增加/减少一行方法
    clearLastRowDataAge(index) {
      if (this.gridData.length === 1) {
        const lastRow = this.gridData[0];
        for (const key in lastRow) {
          if (Object.hasOwnProperty.call(lastRow, key)) {
            lastRow[key] = null; // 清空数据
          }
        }
        localStorage.setItem("gridData", JSON.stringify(this.gridData)); // 更新本地存储
      } else if (this.gridData.length > 1) {
        // 删除最后一行
        this.gridData.splice(index, 1);
        localStorage.setItem("gridData", JSON.stringify(this.gridData)); // 删除后更新本地存储
      }
    },
    rowAvailableQty(action, index) {
      if (action === "push" && this.gridDataQty.length < 9) {
        this.gridDataQty.push({
          /* 新行的初始数据 */
        });
      } else if (action === "del" && this.gridDataQty.length > 1) {
        this.gridDataQty.splice(index, 1);
        localStorage.setItem("gridDataQty", JSON.stringify(this.gridDataQty)); // 删除后更新本地存储
      }
    },
  },
  async mounted() {
    console.log(this.pickerTime, "时间");
    let thisWeek = getTimes({
      type: "week",
      format: "daterange",
    });
    this.pickerTime = thisWeek;
    this.pickerTimeAdd = thisWeek;
    await this.fetchOverviewData("");
    // 获取图表容器
    const chartStyle = document.getElementById("chart-style"); //获取会员类型分布
    const chartAdd = document.getElementById("chart-containerAdd"); //获取会员增长
    const myChartStyle = this.$echarts.init(chartStyle); //初始化会员类型分布
    this.chartSex = this.$echarts.init(document.getElementById("chart-sex-right")); // 获取 性别 实例
    this.updateChart(); // 性别分析 初始化时更新图表数据
    this.initChart(); //销售趋势
    this.initChartStyleRight(); // 调用初始化消费次数的函数
    this.updateChartAge(this.pieChartDataAgeByQty); // 初始化时默认展示人数的圆环图
    this.initChartAdd(); //会员增长
    console.log("this.overviewData.onlineQtyRate", this.overviewData.onlineQtyRate);
    console.log("this.overviewData.offlineQtyRate", this.overviewData.offlineQtyRate);
    // 使用 echarts 渲染图表
    myChartStyle.setOption({
      //获取会员类型分布
      tooltip: {
        trigger: "item", // 提示框触发类型
      },
      legend: {
        top: "bottom", // 图例组件的垂直位置
        selectedMode: true, // 关闭图例的点击事件
        icon: "circle",
      },
      series: [
        {
          // name: "Access From", // 系列名称
          type: "pie", // 图表类型为饼图
          radius: ["40%", "50%"], // 饼图的内外半径
          center: ["50%", "50%"], // 饼图的中心位置
          startAngle: 180, // 饼图的起始角度
          label: {
            show: true, // 是否显示标签
            formatter(param) {
              return param.name + " (" + param.percent + "%)"; // 标签的显示格式
            },
            color: "#333",
          },
          data: [
            {
              value: this.overviewData.onlineQtyRate,
              name: "线上会员总数占比",
              itemStyle: { color: "#fa8219" },
            }, // 数据项
            {
              value: this.overviewData.offlineQtyRate,
              name: "线下会员总数占比",
              itemStyle: { color: "#40b5ff" },
            },
          ],
        },
      ],
    });
    // 监听窗口大小变化，实现图表的自适应
    window.addEventListener("resize", () => {
      myChartStyle.resize();
    });
    this.manData = this.overviewData.maleQty; //性别分析
    this.maleData = this.overviewData.femaleQty; //性别分析
    this.unknowData = this.overviewData.unknownQty; //性别分析
    this.handleSelect(this.activeIndex); //性别分析 人数金额选项卡切换
  },
};
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px;
  background-color: #eaeaea;
  .contain {
    margin-top: 50px;
    margin-bottom: 50px;

    display: flex;
    justify-content: center;
  }
  .containTwo {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: space-around;
  }
  // 会员预览
  .box {
    // display: flex;
    // align-items: center; /* 垂直居中对齐 */
    // justify-content: center; /* 水平居中对齐 */
    height: 86px;
    width: 190px;
    background-color: #f7f8f9;
    border-radius: 4px;
    font-size: 15px;
    margin-left: 4px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .num {
      font-weight: bold;
      font-size: 20px;
      color: #409eff;
    }
    .label {
      font-size: 12px;
    }
  }
  .boxTwo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    width: 300px;
    height: 96px;
    background-color: #f7f8f9;
    font-size: 12px;
    margin-left: 4px;
  }

  //分布选择
  .distributionSelect {
    width: 130px;
    ::v-deep .el-input--medium .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
}
::v-deep .goodsTitle {
  border: none;
}
//分布标题
.distributionTitleText {
  // margin-left: 16px;
  font-weight: 700;
}
// 会员类型分布
// .styleBox {
//   display: flex;
// }
.chart-container {
  // display: flex;
  justify-content: center;
  align-items: center;
}
.styleLeft {
  // flex-grow: 1;
  margin-right: 10px;
  width: 100%; //卡片的高宽
  height: 366px;
  justify-content: center;
  align-items: center;
}

.styleRight {
  // flex-grow: 1;
  width: 100%; //卡片的高宽
  height: 366px;
}
.ageLeft {
  width: 855px;
  height: 380px;
}

.bgc {
  margin-bottom: 10px;
  width: 160px;
  height: 80px;
  background: #f7f8f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.data {
  font-size: 20px; //数字格式
  margin-left: 20px;
}
.recharge {
  //字体
  font-size: 14px;
  margin-left: 20px;
}
.styleLeftAge {
  // flex-grow: 1;
  margin-right: 10px;
  width: 100%; //卡片的高宽
  height: 100%;
  justify-content: center;
  align-items: center;
}

.styleRightQty {
  // flex-grow: 1;
  width: 100%; //卡片的高宽
  height: 470px;
}
.rightBox {
  margin-top: 0px;
  // border: 1px solid red;
  height: 400px;
  width: 220px;
}
.ageBgc {
  margin-bottom: 10px;
  width: 200px;
  height: 38px;
  background: #f7f8f9;
}
.ageData {
  font-size: 14px; //数字格式
  margin-left: 5px;
  color: #505051;
  min-width: 50px;
}
.ageRecharge {
  //字体
  font-size: 14px;
  margin-left: 15px;
}

//单据日期
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 220px !important;
  margin-right: 5px;
}

//输入框原生属性 /** * 解决el-input设置类型为number时，中文输入法光标上移问题
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/** *
解决el-input设置类型为number时，去掉输入框后面上下箭头 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.top {
  width: 270px;
  height: 116px;
  background: #f7f8f9;
  color: black;

  // border: 1px solid pink;
}
.rechargeRace {
  margin-top: 5px;
  font-size: 14px;
  color: #666666;
}
.rechargeFont {
  margin-top: 10px;
  // margin-left: 10;
  font-size: 15px;
}
.info2 {
  width: 200px;
  background: #f7f8f9;
  margin-top: 15px;
  // border: 1px solid burlywood;
}
::v-deep canvas {
  width: 100% !important;
}
.dashed-line {
  width: 2px;
  height: 38px;
  border-left: 1px dashed #cccccc;
  margin: 0 10px;
}
.red-text {
  color: red;
}
.green-text {
  color: green;
}
.el-menu--horizontal > .el-menu-item {
  line-height: 10px;
  height: 25px;
}
.el-menu-item {
  padding: 0px 10px;
}
.info-data {
  color: red;
}
.infoHuan-data {
  color: green;
}
</style>
