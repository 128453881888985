var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vipLevelDialog" },
    [
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 500, showDialog: _vm.openDialog },
        on: {
          "update:showDialog": function ($event) {
            _vm.openDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.levelForm,
                          rules: _vm.rules,
                          "label-width": "130px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "distributionLevelNo",
                            staticClass: "w100",
                            attrs: {
                              label: "分销员等级编码",
                              prop: "distributionLevelNo",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "vipInput",
                              attrs: {
                                placeholder: "请输入分销员等级编码(1-20字符)",
                                maxlength: "20",
                                size: "mini",
                              },
                              model: {
                                value: _vm.levelForm.distributionLevelNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.levelForm,
                                    "distributionLevelNo",
                                    $$v
                                  )
                                },
                                expression: "levelForm.distributionLevelNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "distributionLevelName",
                            staticClass: "w100",
                            attrs: {
                              label: "分销员级别名称",
                              prop: "distributionLevelName",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "vipInput",
                              attrs: {
                                placeholder: "请输入分销员级别名称(1-40字符)",
                                maxlength: "40",
                                size: "mini",
                              },
                              model: {
                                value: _vm.levelForm.distributionLevelName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.levelForm,
                                    "distributionLevelName",
                                    $$v
                                  )
                                },
                                expression: "levelForm.distributionLevelName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "w100",
                            attrs: {
                              label: "设置升级规则",
                              prop: "shopRangeType",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "ruleForm" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.levelForm.puchaseAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.levelForm,
                                          "puchaseAmount",
                                          $$v
                                        )
                                      },
                                      expression: "levelForm.puchaseAmount",
                                    },
                                  },
                                  [_vm._v("自购金额")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("span", [_vm._v("满")]),
                                    _c("el-input", {
                                      staticClass: "inputNumberW",
                                      attrs: {
                                        type: "number",
                                        disabled: !_vm.levelForm.puchaseAmount,
                                        oninput:
                                          "value=value.replace(/^0|[^0-9]/g,'')",
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.levelForm.puchaseAmountNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.levelForm,
                                            "puchaseAmountNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "levelForm.puchaseAmountNumber",
                                      },
                                    }),
                                    _c("span", [_vm._v("元")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ruleForm" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.levelForm.promotionAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.levelForm,
                                          "promotionAmount",
                                          $$v
                                        )
                                      },
                                      expression: "levelForm.promotionAmount",
                                    },
                                  },
                                  [_vm._v("推广金额")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("span", [_vm._v("满")]),
                                    _c("el-input", {
                                      staticClass: "inputNumberW",
                                      attrs: {
                                        disabled:
                                          !_vm.levelForm.promotionAmount,
                                        oninput:
                                          "value=value.replace(/^0|[^0-9]/g,'')",
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.levelForm.promotionAmountNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.levelForm,
                                            "promotionAmountNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "levelForm.promotionAmountNumber",
                                      },
                                    }),
                                    _c("span", [_vm._v("元")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ruleForm" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.levelForm.developMember,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.levelForm,
                                          "developMember",
                                          $$v
                                        )
                                      },
                                      expression: "levelForm.developMember",
                                    },
                                  },
                                  [_vm._v("发展会员数")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("span", [_vm._v("满")]),
                                    _c("el-input", {
                                      staticClass: "inputNumberW",
                                      attrs: {
                                        disabled: !_vm.levelForm.developMember,
                                        oninput:
                                          "value=value.replace(/^0|[^0-9]/g,'')",
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.levelForm.developMemberNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.levelForm,
                                            "developMemberNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "levelForm.developMemberNumber",
                                      },
                                    }),
                                    _c("span", [_vm._v("人")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ruleForm" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    model: {
                                      value: _vm.levelForm.inviteDistributors,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.levelForm,
                                          "inviteDistributors",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "levelForm.inviteDistributors",
                                    },
                                  },
                                  [_vm._v("邀请分销员")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("span", [_vm._v("满")]),
                                    _c("el-input", {
                                      staticClass: "inputNumberW",
                                      attrs: {
                                        disabled:
                                          !_vm.levelForm.inviteDistributors,
                                        oninput:
                                          "value=value.replace(/^0|[^0-9]/g,'')",
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.levelForm
                                            .inviteDistributorsNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.levelForm,
                                            "inviteDistributorsNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "levelForm.inviteDistributorsNumber",
                                      },
                                    }),
                                    _c("span", [_vm._v("人")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "isDefaultLevel",
                            staticClass: "w100",
                            attrs: {
                              label: "是否默认级别",
                              prop: "isDefaultLevel",
                            },
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value: _vm.levelForm.isDefaultLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "isDefaultLevel", $$v)
                                },
                                expression: "levelForm.isDefaultLevel",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "sortNo",
                            staticClass: "w100",
                            attrs: { label: "排序", prop: "sortNo" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "vipInput",
                              attrs: {
                                placeholder: "请输入排序",
                                maxlength: "40",
                                oninput: "value=value.replace(/^0|[^0-9]/g,'')",
                                size: "mini",
                              },
                              model: {
                                value: _vm.levelForm.sortNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "sortNo", $$v)
                                },
                                expression: "levelForm.sortNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "remark",
                            staticClass: "w100",
                            attrs: { label: "备注" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2, maxRows: 4 },
                                placeholder: "请输入备注(1-80字符)",
                                maxlength: "80",
                              },
                              model: {
                                value: _vm.levelForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "remark", $$v)
                                },
                                expression: "levelForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    on: {
                      click: function ($event) {
                        _vm.openDialog = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addlevel(true)
                      },
                    },
                  },
                  [_vm._v("保存并新增")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addlevel(false)
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }